import { z } from "zod";

export enum IdentityRole {
  Owner = "owner",
  Admin = "admin",
  Editor = "editor",
  Creator = "creator",
  Client = "client",
}

export enum OnboardingCalloutKey {
  Dashboard = "dashboard",
  Build = "build",
  Create = "create",
  Ideas = "ideas",
  SmartBlocks = "smartBlocks",
  Stacks = "stacks",
  FirstBlockOpen = "firstBlockOpen",
  FirstCreate = "firstCreate",
}

export const IdentityRoleSchema = z.nativeEnum(IdentityRole);
export const IdentitySettingsSchema = z.object({
  onboardingCalloutProgress: z.nativeEnum(OnboardingCalloutKey).optional(),
  onboardingCalloutOneOff: z
    .array(z.nativeEnum(OnboardingCalloutKey))
    .default([])
    .optional(),
});

export const IdentitySchema = z.object({
  id: z.string().uuid(),
  name: z.string().nullable(),
  email: z.string(),
  primaryRole: IdentityRoleSchema,
  organizationId: z.string().uuid(),
  authUserId: z.string().nullable().optional(),
  settings: IdentitySettingsSchema,
  createdAt: z.date(),
});

/**
 * Minimal subset of Identity used for e.g including creator data as part of
 * another record (fact->creator->...)
 */
export const IdentityEmbedSchema = z.object({
  id: IdentitySchema.shape.id,
  name: IdentitySchema.shape.name.nullable(),
  email: IdentitySchema.shape.email.nullable(),
});

export const IdentityWithAccountIdSchema = IdentitySchema.merge(
  z.object({
    accountId: z.string().uuid(),
  }),
);

export const IdentitySummarySchema = z.object({
  isOperationsUser: z.boolean(),
  isAccountActive: z.boolean(),
  isPayingCustomer: z.boolean(),
  isCustomer: z.boolean(),
  hasInsightsAccess: z.boolean(),
  identity: IdentityWithAccountIdSchema.nullable(),
  mustCompleteOnboarding: z.boolean(),
});

export const UpdateIdentitySchema = z.object({
  settings: IdentitySchema.shape.settings.optional(),
  name: IdentitySchema.shape.name.optional(),
  role: IdentitySchema.shape.primaryRole.optional(),
});

export type IdentitySummary = z.infer<typeof IdentitySummarySchema>;
export type Identity = z.infer<typeof IdentitySchema>;
export type IdentityWithAccountId = z.infer<typeof IdentityWithAccountIdSchema>;
export type IdentitySettings = z.infer<typeof IdentitySettingsSchema>;
export type UpdateIdentity = z.infer<typeof UpdateIdentitySchema>;
