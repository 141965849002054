import { z } from "zod";
import { Period } from "../utils/date";
import { IdentityEmbedSchema } from "./Identity";

export enum FactProvenance {
  User = "user",
  StrategyGenerator = "strategyGenerator",
  SiteScraper = "siteScraper",
  StripeConnector = "stripeConnector",
}

const FactContentAmountItemSchema = z.object({
  createdAt: z.number(),
  value: z.number(),
});

const FactContentAmountFormat = z.object({
  // What the amount represents (e.g. net, gross)
  name: z.string(),
  period: z.nativeEnum(Period),
  currency: z.string(),
  value: z.number(),
  items: z.array(FactContentAmountItemSchema).optional(),
  previousPeriodItems: z.array(FactContentAmountItemSchema).optional(),
});

export const FactContentSchema = z.object({
  /**
   * Specifies where this content originates from - usually the user or
   * generated by something.
   */
  provenance: z.nativeEnum(FactProvenance).default(FactProvenance.User),

  /**
   * Lists references included in the content - such as block path references.
   *
   * This should be used for secondary UI, and not necessarily for queries --
   * anything we'll be querying against should be normalized out of this list.
   */
  references: z
    .array(
      z.discriminatedUnion("refType", [
        z.object({
          refType: z.literal("blockPath"),
          blockPath: z.string(),
        }),
      ]),
    )
    .optional()
    .default([]),

  /**
   * Specifies formatting options for this content - currently we only
   * have text, but later we may want to process facts differently (e.g
   * earnings as specific currency)
   */
  format: z.discriminatedUnion("type", [
    // Covers both plain text, and eventually rich text (e.g json markup)
    z.object({
      type: z.literal("text"),
    }),
    // Generic numeric data type for any amount values with currency or other metrics
    z.object({ type: z.literal("amount") }).merge(FactContentAmountFormat),
  ]),

  plainText: z.string().optional(),
});

export const FactSchema = z.object({
  id: z.string().uuid(),
  creatorId: z.string().uuid(),
  organizationId: z.string().uuid(),
  blockPath: z.string(),
  parentFactId: z.string().uuid().nullable().optional(),
  content: FactContentSchema,
  factDependencyIds: z.array(z.string().uuid()).default([]),
  createdAt: z.date(),
  committedAt: z.date().nullable().optional().nullable(),
  committedById: z.string().uuid().nullable().optional().nullable(),
  commitVersion: z.string().uuid().nullable().optional().nullable(),
  creator: IdentityEmbedSchema,
});

export const FactWithStateSchema = FactSchema.extend({
  outdatedReferenceBlockPaths: z.array(z.string()).default([]),
});

export const CreateFactSchema = FactSchema.pick({
  blockPath: true,
  parentFactId: true,
  content: true,
}).merge(
  z.object({
    id: FactSchema.shape.id.optional(),
  }),
);

export type FactContent = z.infer<typeof FactContentSchema>;
export type Fact = z.infer<typeof FactSchema>;
export type FactWithState = z.infer<typeof FactWithStateSchema>;
export type CreateFact = z.infer<typeof CreateFactSchema>;
export type FactContentAmountItem = z.infer<typeof FactContentAmountItemSchema>;

export function getFactContentText(fact: Fact) {
  return fact.content.plainText ?? "";
}
