import {
  Avatar,
  Card,
  Divider,
  Group,
  SimpleGrid,
  Spoiler,
  Stack,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import clsx from "clsx";
import { IdentityRole, WidgetId } from "core";
import { useNavigate } from "react-router-dom";
import { useIsRole } from "../../../auth/useIdentity";
import { useTheme } from "../../../components/ThemeProvider/useTheme";
import { EntityTypeBadge } from "../../../components/badges/EntityTypeBadge";
import { relativeTimeFromNow } from "../../../utils/date";
import { useDashboard } from "../useDashboard";
import { BaseWidget, QuickLinkButton } from "./BaseWidget";
import baseWidgetClasses from "./BaseWidget.module.css";
import classes from "./TeamWidget.module.css";
import { widgetProps } from "./config";

export function TeamWidget() {
  const navigate = useNavigate();
  const { theme } = useTheme();

  const { stats } = useDashboard();
  const isClient = useIsRole(IdentityRole.Client);

  const hasActivities = stats.recentActivities.length > 0;

  const teamStats = [
    {
      title: "Assets created",
      value: stats.totalAssets,
      redirectTo: "/create/all",
      quickLinkRedirecTo: "/create",
    },
    ...(!isClient
      ? [
          {
            title: "Blocks completed",
            value: stats.totalBlocksCompleted,
            redirectTo: "/build",
          },
        ]
      : []),
    ...(!isClient
      ? [
          {
            title: "Stacks completed",
            value: stats.totalCompletedStacks,
            redirectTo: "/build",
          },
        ]
      : []),
    {
      title: "Tasks completed",
      value: stats.totalCompletedTasks,
    },
  ];

  const emptyStats = teamStats.filter((stat) => stat.value === 0);
  const isOnlyEmptyStats = teamStats.length === emptyStats.length;
  const filledStats = teamStats.filter((stat) => stat.value > 0);
  const hasFilledStats = filledStats.length > 0;

  if (isClient && isOnlyEmptyStats) return null;

  return (
    <BaseWidget
      title={widgetProps[WidgetId.Team].label}
      cardClassName={clsx(
        isOnlyEmptyStats && baseWidgetClasses.fixedWidgetCard,
      )}
    >
      {hasFilledStats && (
        <SimpleGrid cols={2} className={classes.teamStats}>
          {filledStats.map((stat) => (
            <Stack
              key={stat.title}
              gap={0}
              className={clsx({
                [classes.teamStatCard]: !!stat.redirectTo,
                [classes.clickable]: !!stat.redirectTo,
              })}
              onClick={() => {
                if (stat.redirectTo) navigate(stat.redirectTo);
              }}
            >
              <Title order={2} fw="bold">
                {stat.value.toString().padStart(2, "0")}
              </Title>
              <Text size="sm">{stat.title}</Text>
            </Stack>
          ))}
        </SimpleGrid>
      )}
      {isOnlyEmptyStats && (
        <QuickLinkButton
          label="Start building in Build mode to unlock."
          onClick={() => navigate("/build")}
        />
      )}
      {hasActivities && (
        <>
          <Divider />
          <Spoiler
            hideLabel="Show less"
            showLabel="See more"
            styles={{
              content: {
                margin: "0px -12px",
                padding: "0px 12px",
              },
              control: {
                marginTop: 10,
                fontSize: 12,
              },
            }}
            maxHeight={290}
          >
            <Stack gap="xs" pb="lg">
              {stats.recentActivities.map((activity) => (
                <Card
                  p="xs"
                  key={activity.id}
                  shadow="md"
                  className={clsx(
                    classes.teamLastActivityCard,
                    activity.redirectTo && classes.clickable,
                  )}
                  onClick={
                    activity.redirectTo
                      ? () => navigate(activity.redirectTo!)
                      : undefined
                  }
                >
                  <Group gap="xs" wrap="nowrap" align="flex-start">
                    <Tooltip withArrow label={activity.updatorName}>
                      <Avatar
                        variant="light"
                        color={theme.colors[theme.secondaryColor][4]}
                        size="sm"
                      >
                        {activity.updatorInitial}
                      </Avatar>
                    </Tooltip>
                    <Stack gap={4}>
                      <EntityTypeBadge label={activity.type} />
                      <Tooltip withArrow label={activity.title}>
                        <Text size="xs" lineClamp={1} mt={4}>
                          {activity.headlinePrefix ??
                            (activity.updatedAt ? "Updated" : "Created")}{" "}
                          "{activity.title}"
                        </Text>
                      </Tooltip>
                      <Text size="xs" c="dimmed">
                        {relativeTimeFromNow(
                          activity.updatedAt ?? activity.createdAt,
                        )}
                      </Text>
                    </Stack>
                  </Group>
                </Card>
              ))}
            </Stack>
          </Spoiler>
        </>
      )}
    </BaseWidget>
  );
}
