import {
  ActionIcon,
  ActionIconGroup,
  HoverCard,
  HoverCardDropdown,
  HoverCardTarget,
  Menu,
  Tooltip,
} from "@mantine/core";
import { useInViewport, useToggle } from "@mantine/hooks";
import {
  IconArrowAutofitHeight,
  IconBackspace,
  IconCopy,
  IconCopyCheck,
  IconMoodSmile,
  IconReload,
  IconRepeat,
  IconTextGrammar,
} from "@tabler/icons-react";
import MarkdownPreview from "@uiw/react-markdown-preview";
import { useCallback, useEffect, useRef } from "react";
import { Popover as TextSelectionPopover } from "react-text-selection-popover";
import rehypeSanitize from "rehype-sanitize";
import { TextCopier } from "../TextCopier";
import classes from "./MarkdownContent.module.css";

type RetrySelectionAction = {
  type: "retrySelection";
  selectionText?: string;
};

type SimplifySelectionAction = {
  type: "simplifySelection";
  selectionText?: string;
};

type ShortenSelectionAction = {
  type: "shortenSelection";
  selectionText?: string;
};
type LengthenSelectionAction = {
  type: "lengthenSelection";
  selectionText?: string;
};

type FixSelectionAction = {
  type: "fixGrammarSelection";
  selectionText?: string;
};

function clampText(
  text: string,
  {
    length = 32,
    suffix = "...",
  }: {
    length?: number;
    suffix?: string;
  } = {},
) {
  if (text.length <= length) {
    return text;
  }

  /**
   * Clamp the text, ensuring the suffix doesn't push the output over the desired
   * limit length:
   */
  return `${text.slice(0, length - suffix.length)}${suffix}`;
}

export type TextSelectionAction =
  | SimplifySelectionAction
  | ShortenSelectionAction
  | LengthenSelectionAction
  | RetrySelectionAction
  | FixSelectionAction;

const rehypePlugins = [rehypeSanitize];
const markdownDisallowedElements = ["img", "script", "link", "iframe"];

export function MarkdownContent({
  content,
  onSelectionAction,
  withSelectionActions = false,
  lazyRendering = true,
  preview = false,
  style,
}: {
  content?: string;
  onSelectionAction?: (action: TextSelectionAction) => void;

  /**
   * If true, enables the texd selection popover
   */
  withSelectionActions?: boolean;

  /**
   * If true, tries to be lazy about rendering the markdown block, for example,
   * by suppressing it if outside the active viewport.
   */
  lazyRendering?: boolean;

  /**
   * Exposed so we can use Transition
   */
  style?: React.CSSProperties;

  /**
   * Render only a small amount of (preview) content, and try to make it look OK
   */
  preview?: boolean;
}) {
  const { ref, inViewport } = useInViewport();
  const previewRef = useRef<HTMLDivElement>(null);
  const [isRendered, toggleIsRendered] = useToggle();

  useEffect(() => {
    if (inViewport && !isRendered) {
      toggleIsRendered(true);
    }
  }, [inViewport, isRendered, toggleIsRendered]);

  const onRunSelectionAction = useCallback(
    (action: TextSelectionAction) => {
      if (onSelectionAction) {
        onSelectionAction(action);
      }

      const selection = window.getSelection();
      if (selection) {
        selection.empty();
      }
    },
    [onSelectionAction],
  );

  return (
    <div ref={ref}>
      {lazyRendering === false || isRendered ? (
        <>
          <MarkdownPreview
            skipHtml
            rehypePlugins={rehypePlugins}
            disallowedElements={markdownDisallowedElements}
            className={classes.markdownWrapperElement}
            source={
              preview && content
                ? clampText(content, {
                    length: 200,
                  })
                : content
            }
            wrapperElement={{
              ref: previewRef,
              style,
            }}
            components={{
              a: (v) => <a target="_blank">{v.children}</a>,
            }}
          />
          {previewRef.current && withSelectionActions && (
            <TextSelectionPopover
              target={previewRef.current}
              render={({ clientRect, isCollapsed, textContent }) => {
                if (clientRect == null || isCollapsed) return null;

                return (
                  <div
                    className={classes.selectionPopover}
                    style={{
                      top: window.scrollY + clientRect.top - 60,
                    }}
                  >
                    <ActionIconGroup
                      className={classes.selectionPopoverActions}
                    >
                      <Menu>
                        <HoverCard position="bottom-start">
                          <HoverCardTarget>
                            <ActionIcon
                              component="button"
                              variant="default"
                              size="lg"
                            >
                              <IconReload />
                            </ActionIcon>
                          </HoverCardTarget>
                          <HoverCardDropdown>
                            <Menu.Dropdown>
                              <Tooltip label="Correct spelling mistakes and grammatical errors for this selection">
                                <Menu.Item
                                  leftSection={<IconTextGrammar />}
                                  onClick={() =>
                                    onRunSelectionAction({
                                      selectionText: textContent,
                                      type: "fixGrammarSelection",
                                    })
                                  }
                                >
                                  Fix spelling & grammar
                                </Menu.Item>
                              </Tooltip>
                              <Tooltip label="Simplify the content for this selection">
                                <Menu.Item
                                  leftSection={<IconMoodSmile />}
                                  onClick={() =>
                                    onRunSelectionAction({
                                      selectionText: textContent,
                                      type: "simplifySelection",
                                    })
                                  }
                                >
                                  Simplify
                                </Menu.Item>
                              </Tooltip>
                              <Tooltip label="Use fewer words">
                                <Menu.Item
                                  leftSection={<IconBackspace />}
                                  onClick={() =>
                                    onRunSelectionAction({
                                      selectionText: textContent,
                                      type: "shortenSelection",
                                    })
                                  }
                                >
                                  Shorten
                                </Menu.Item>
                              </Tooltip>
                              <Tooltip label="Expand the text by adding more detail or explanation">
                                <Menu.Item
                                  leftSection={<IconArrowAutofitHeight />}
                                  onClick={() =>
                                    onRunSelectionAction({
                                      selectionText: textContent,
                                      type: "lengthenSelection",
                                    })
                                  }
                                >
                                  Lengthen
                                </Menu.Item>
                              </Tooltip>
                              <Tooltip label="Try again, with a different result for this selection">
                                <Menu.Item
                                  leftSection={<IconRepeat />}
                                  onClick={() =>
                                    onRunSelectionAction({
                                      selectionText: textContent,
                                      type: "retrySelection",
                                    })
                                  }
                                >
                                  Try again
                                </Menu.Item>
                              </Tooltip>
                            </Menu.Dropdown>
                          </HoverCardDropdown>
                        </HoverCard>
                      </Menu>
                      <TextCopier content={textContent!}>
                        {(copy, copied) => (
                          <ActionIcon
                            variant={copied ? "filled" : "default"}
                            size="lg"
                            onClick={copy}
                            color={copied ? "green" : undefined}
                          >
                            {copied ? <IconCopyCheck /> : <IconCopy />}
                          </ActionIcon>
                        )}
                      </TextCopier>
                    </ActionIconGroup>
                  </div>
                );
              }}
            />
          )}
        </>
      ) : null}
    </div>
  );
}
