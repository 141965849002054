import classes from "./StackIcons.module.css";

export function FundIcon() {
  return (
    <svg
      width="205"
      height="230"
      viewBox="0 0 205 230"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.stackIcon}
    >
      <path
        d="M102.431 94.9007L85.387 104.741L102.352 114.537L119.243 104.785L119.475 104.782L136.519 114.623L119.509 124.443L136.521 134.265L119.476 144.106L68.3423 114.583V94.9016L85.3867 85.0599L102.431 94.9007Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.2 143.841L34.1992 85.8997C34.1991 79.469 37.6298 73.5268 43.1988 70.3113L93.3769 41.3393C98.9462 38.1237 105.808 38.1236 111.377 41.3393L161.557 70.3114C167.126 73.5269 170.557 79.4691 170.557 85.8997L170.557 143.841C170.557 150.272 167.126 156.214 161.557 159.43L111.378 188.4C105.809 191.616 98.9473 191.616 93.378 188.4L43.1999 159.43C37.6308 156.214 34.2001 150.272 34.2 143.841ZM51.2445 85.3483L51.2449 144.393L102.378 173.915L153.511 144.392L153.512 85.3484L102.378 55.8251L51.2445 85.3483Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M204.537 69.724L204.536 154.274C204.536 166.453 198.038 177.707 187.491 183.796L114.268 226.072C106.842 230.359 97.6937 230.359 90.2681 226.072L12 180.884C4.57443 176.597 6.84045e-05 168.674 4.77098e-05 160.099L0 75.548C2.01768e-05 63.3691 6.49753 52.1153 17.0448 46.0259L90.269 3.7511C97.6945 -0.536001 106.843 -0.536013 114.269 3.75107L192.537 48.9394C199.963 53.2266 204.537 61.1497 204.537 69.724ZM21.0448 63.398L98.2688 18.8138C100.744 17.3848 103.794 17.3848 106.269 18.8138L183.492 63.3988C185.967 64.8279 187.492 67.469 187.492 70.3271L187.491 159.496C187.491 162.354 185.966 164.995 183.491 166.424L106.268 211.009C103.793 212.438 100.743 212.438 98.2683 211.009L21.0447 166.424C18.5695 164.995 17.0448 162.354 17.0448 159.496L17.0447 70.3262C17.0447 67.4681 18.5695 64.827 21.0448 63.398Z"
        fill="currentColor"
      />
    </svg>
  );
}
