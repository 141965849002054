import {
  defaultOrganizationStacks,
  FeatureFlag,
  rolesWithLimitedBlockAccess,
  type Fact,
} from "core";
import { useCallback, useMemo } from "react";
import { useAuthContext } from "../auth/useAuthContext";
import { useExtraStackUnlockedNotification } from "../components/FeatureUnlockedModal/useExtraStackUnlockedNotification";
import { useIsPreviewMode } from "./useAccount";
import { useAllowedBlocks } from "./useFacts";
import { useIsFeatureEnabled } from "./useIsFeatureEnabled";
import { useOrganizationStacks } from "./useOrganization";
import { BlocksGroupedByStack, Module, Pillar, useStacks } from "./useStacks";

function useIsBlockReadable() {
  const canUseBlocksRestricted = useIsFeatureEnabled(
    FeatureFlag.UseBlocksRestricted,
  );
  const { identity } = useAuthContext();
  const { blocks } = useAllowedBlocks({
    role: identity.primaryRole,
  });

  /** A block is readable when:
   * - Is is available for the current logged in user 'role'
   * AND
   * - It is not restricted from Storyblok
   * - OR it is restricted from Storyblok and available to the users set from the feature flag
   */
  const isBlockReadable = useCallback(
    (block: Module) => {
      // Roles that depend on blocks permissions settings by owners or admins.
      const isRestrictedRole = rolesWithLimitedBlockAccess.includes(
        identity.primaryRole,
      );
      const canUseBlockRestrictedByPermission =
        !isRestrictedRole ||
        (isRestrictedRole && blocks?.includes(block.knowledgeslug!));

      // Most likely NOAN internal users of some beta users for testing blocks before publishing to all users.
      const canUseBlockRestrictedByFlag =
        !block.restricted || (block.restricted && canUseBlocksRestricted);

      return canUseBlockRestrictedByPermission && canUseBlockRestrictedByFlag;
    },
    [canUseBlocksRestricted, identity.primaryRole, blocks],
  );

  return { isBlockReadable };
}

export function useIsStackAccessible() {
  const {
    stacks: organizationStacks,
    isLoadingStacks: isLoadingOrganizationStacks,
  } = useOrganizationStacks();
  const isPreviewMode = useIsPreviewMode();
  const { isUnlocked: isExtraStackUnlocked } =
    useExtraStackUnlockedNotification();
  const isExtraStackUsed =
    organizationStacks.length > defaultOrganizationStacks.length;

  const isStackAccessible = useCallback(
    (slug: string) => {
      const belongsToOrgStacks = organizationStacks.includes(slug);
      const isReadable =
        !isPreviewMode || (isPreviewMode && belongsToOrgStacks);
      const isAddable =
        !belongsToOrgStacks &&
        (!isPreviewMode ||
          (isPreviewMode && isExtraStackUnlocked && !isExtraStackUsed));

      return {
        isReadable,
        isEditable: belongsToOrgStacks,
        isAddable,
      };
    },
    [isExtraStackUnlocked, isExtraStackUsed, isPreviewMode, organizationStacks],
  );

  return {
    isStackAccessible,
    isLoadingOrganizationStacks,
  };
}

export function useFilteredStacksWithOnlyReadableBlocks() {
  const { stacks, isLoading, isFetching } = useStacks();
  const { isBlockReadable } = useIsBlockReadable();

  const viewableStacks = useMemo(
    () =>
      stacks
        ?.filter((st) => st.content.modules.some(isBlockReadable))
        .map((st) => {
          return {
            ...st,
            content: {
              ...st.content,
              // Only return the modules that are allowed
              modules: st.content.modules.filter(isBlockReadable),
            },
          };
        }),
    [isBlockReadable, stacks],
  );

  return { stacks: viewableStacks, isLoadingStacks: isLoading || isFetching };
}

/**
 * Mashes all the strategy data together into a convenient
 * shape:
 */
export function groupModulesByStack(
  stacks: Pillar[],
  facts: Fact[],
): BlocksGroupedByStack[] {
  return (
    stacks
      .map((sta) => ({
        stack: {
          ...sta,
          description: sta.content.descriptive_summary,
        },
        blocks: sta.content.modules.map((mod) => ({
          ...mod,
          // Match module with facts data:
          fact: facts.find(({ blockPath }) => blockPath === mod.knowledgeslug),
          stackSlug: sta.slug,
          stackName: sta.name,
        })),
      }))
      // Somewhat wastefully, remove all stacks with no modules after all filtering is done
      .filter((p) => p.blocks.length > 0)
  );
}
