import type { FeatureId, FeatureSettingFilters, FeatureSettingGet } from "core";
import { trpc } from "./trpc";

export function useFeatureSettings(input: FeatureSettingFilters) {
  const { data, isLoading } = trpc.featureSetting.getAll.useQuery(input);

  return {
    settings: data,
    isLoadingSettings: isLoading,
  };
}

export function isFeatureSettingEnabled(
  featureId: FeatureId,
  settings?: { featureId: string; enabled: boolean }[],
  defaultEnabled = true,
) {
  return (
    settings?.find((setting) => setting.featureId === featureId)?.enabled ??
    defaultEnabled
  );
}

export function useIsFeatureSettingEnabled(input: FeatureSettingGet) {
  const { data, isLoading } = trpc.featureSetting.get.useQuery(input);

  return {
    /** If the data record does not exist, we consider the setting enabled */
    isEnabled: data?.enabled ?? true,
    isLoadingSetting: isLoading,
  };
}

export function useCreateFeatureSetting() {
  const utils = trpc.useUtils();

  const { mutate, isLoading, ...mutation } =
    trpc.featureSetting.create.useMutation({
      onSettled() {
        utils.featureSetting.getAll.invalidate();
      },
    });

  return {
    ...mutation,
    createFeatureSetting: mutate,
    isCreatingFeatureSetting: isLoading,
  };
}

export function useUpdateFeatureSetting() {
  const utils = trpc.useUtils();

  const { mutate, isLoading, ...mutation } =
    trpc.featureSetting.update.useMutation({
      onSettled() {
        utils.featureSetting.getAll.invalidate();
      },
    });

  return {
    ...mutation,
    updateFeatureSetting: mutate,
    isUpdatingFeatureSetting: isLoading,
  };
}
