import { Anchor } from "@mantine/core";
import { WidgetId } from "core";
import {
  BuildWidget,
  ClientModeWidget,
  CreateWidget,
  NewsWidget,
  SalesWidget,
  SitePerformanceWidget,
  TasksWidget,
  TeamWidget,
} from ".";

export type Widget = {
  id: WidgetId;
  type: "fixed" | "flexible";
  content: JSX.Element;
};

export const widgetContent: Record<WidgetId, JSX.Element> = {
  [WidgetId.Build]: <BuildWidget />,
  [WidgetId.Create]: <CreateWidget />,
  [WidgetId.News]: <NewsWidget />,
  [WidgetId.Team]: <TeamWidget />,
  [WidgetId.Tasks]: <TasksWidget />,
  [WidgetId.Sales]: <SalesWidget />,
  [WidgetId.SitePerformance]: <SitePerformanceWidget />,
  [WidgetId.ClientMode]: <ClientModeWidget />,
};

export const widgetProps = {
  [WidgetId.Build]: {
    label: "Build",
    description: "",
  },
  [WidgetId.Create]: { label: "Create", description: "" },
  [WidgetId.News]: {
    label: "News",
    description:
      "Stay updated with the latest industry trends and announcements.",
  },
  [WidgetId.Tasks]: {
    label: "Tasks",
    description:
      "Track and manage your daily to-do items for streamlined productivity.",
  },
  [WidgetId.Team]: {
    label: "Latest Updates",
    description:
      "Get real-time updates on changes or new activities in your organization.",
  },
  [WidgetId.Sales]: {
    label: "Sales",
    description:
      "Monitor your sales metrics and performance in a single glance.",
  },
  [WidgetId.SitePerformance]: {
    label: "Website Analytics",
    description: "Power your decisions with Google Analytics.",
  },
  [WidgetId.ClientMode]: {
    label: "Client Onboarding",
    description: (
      <>
        Customize the client onboarding experience by selecting the specific{" "}
        <Anchor size="sm" href="/account/organization/blocks">
          Smart Blocks
        </Anchor>{" "}
        they need to complete.
      </>
    ),
  },
};
