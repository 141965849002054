import {
  ActionIcon,
  Card,
  Collapse,
  Group,
  Loader,
  MantineSpacing,
  Stack,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";
import {
  IconArrowUpRight,
  IconBolt,
  IconChevronDown,
  IconChevronUp,
} from "@tabler/icons-react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { useToggle } from "../../../utils/useToggle";
import classes from "./BaseWidget.module.css";

interface BaseWidgetProps {
  title: string;
  redirectTo?: string;
  cardClassName?: string;
  disabled?: boolean;
  childrenContainerGap?: MantineSpacing;
  onClick?: () => void;
}

function ExternalLink({ to }: { to: string }) {
  const navigate = useNavigate();

  return (
    <ActionIcon
      variant="default"
      radius="xl"
      onClick={(e) => {
        e.stopPropagation();
        navigate(to);
      }}
    >
      <IconArrowUpRight size={18} />
    </ActionIcon>
  );
}

export function QuickLinkButton({
  label,
  className,
  isLoading,
  onClick,
}: {
  label: string;
  className?: string;
  isLoading?: boolean;
  onClick: () => void;
}) {
  return (
    <Card
      p="xs"
      className={clsx(classes.quickLink, className, {
        [classes.quickLinkLoading]: isLoading,
      })}
      onClick={!isLoading ? onClick : undefined}
    >
      <Group gap={2} wrap="nowrap">
        <ThemeIcon variant="transparent">
          <IconBolt size={20} />
        </ThemeIcon>
        <Text size="sm">{label}</Text>
        {isLoading && <Loader mx="xs" size="xs" />}
      </Group>
    </Card>
  );
}

export function FixedWidget(props: React.PropsWithChildren<BaseWidgetProps>) {
  return <BaseWidget {...props} cardClassName={classes.fixedWidgetCard} />;
}

export function BaseWidget({
  title,
  redirectTo,
  cardClassName,
  children,
  childrenContainerGap = "lg",
  disabled,
  onClick,
}: React.PropsWithChildren<BaseWidgetProps>) {
  const [isCollapsed, toggleIsCollapsed] = useToggle();

  return (
    <Card
      className={clsx(classes.baseWidgetCard, cardClassName)}
      onClick={onClick}
      mod={{ disabled }}
    >
      <Stack gap="md">
        <Group
          justify="space-between"
          className={classes.cardHeader}
          onClick={() => toggleIsCollapsed()}
        >
          <Title order={5} fw="bold">
            {title}
          </Title>
          <Group gap="xs">
            <ActionIcon variant="transparent" radius="xl" color="dark">
              {!isCollapsed ? <IconChevronUp /> : <IconChevronDown />}
            </ActionIcon>
            {redirectTo && <ExternalLink to={redirectTo} />}
          </Group>
        </Group>
        <Collapse in={!isCollapsed}>
          <Stack gap={childrenContainerGap}>{children}</Stack>
        </Collapse>
      </Stack>
    </Card>
  );
}
