import type Stripe from "stripe";
import { z } from "zod";
import { type FactContentAmountItem } from "./Fact";

export enum SalesBlockPathKeys {
  Sales = "stripe/sales",
  Net = "net",
  Gross = "gross",
  PaymentsSucceeded = "payments-succeeded",
  PaymentsUncaptured = "payments-uncaptured",
  PaymentsRefunded = "payments-refunded",
  PaymentsFailed = "payments-failed",
  Customers = "customers",
  Churns = "churns",
}

export enum StripeCacheKeys {
  PromoCodes = "promo-codes",
}

export const validStatuses: Stripe.Subscription["status"][] = [
  "active",
  "trialing",
  "paused",
];

type PeriodSalesItem = FactContentAmountItem & { net?: number };

export type PeriodSales = {
  grossVolume: number;
  netVolume: number;
  items: PeriodSalesItem[];
  previousPeriodItems: PeriodSalesItem[];
};

export const transactionTypesImpactingNet: Stripe.BalanceTransaction.Type[] = [
  "charge",
  "refund",
  "adjustment",
  "tax_fee",
];

const StripeGetAccessTokenResponseSchema = z.object({
  access_token: z.string(),
  livemode: z.boolean(),
  refresh_token: z.string(),
  scope: z.string(),
  stripe_publishable_key: z.string(),
  stripe_user_id: z.string(),
  token_type: z.string(),
});

const StripeDeauthorizeResponseSchema = z.object({
  stripe_user_id: z.string(),
});

export type StripeGetAccessTokenResponse = z.infer<
  typeof StripeGetAccessTokenResponseSchema
>;
export type StripeDeauthorizeResponse = z.infer<
  typeof StripeDeauthorizeResponseSchema
>;
