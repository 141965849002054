import { notifications } from "@mantine/notifications";
import { IconBug, IconCirclesRelation } from "@tabler/icons-react";
import { Connector } from "core";
import { useState } from "react";
import { openConnect, useGetNangoSessionToken } from "../../utils/nango";
import { useUpsertConnection } from "../../utils/useConnections";

export function usePlatformConnector({
  connector,
  successMessage,
  errorMessage,
}: {
  connector: Connector;
  successMessage?: string;
  errorMessage?: string;
}) {
  const [isConnecting, setIsConnecting] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);

  const { mutateAsync: getSessionToken } = useGetNangoSessionToken();
  const { upsertConnection } = useUpsertConnection();

  async function onOpenConnect() {
    setIsConnecting(true);

    const { sessionToken } = await getSessionToken();

    const connection = await openConnect({
      sessionToken,
      connector,
      onError() {
        setIsConnecting(false);
        setIsSyncing(false);
      },
    });

    setIsConnecting(false);

    if (!connection) {
      return;
    }

    notifications.show({
      color: "green",
      icon: <IconCirclesRelation size={18} />,
      message:
        successMessage ??
        "Your platform is now linked! The data will be available shortly as we process the latest insights.",
    });

    setIsSyncing(true);
    const { connectionId, providerConfigKey } = connection;
    upsertConnection(
      {
        connector,
        nangoConnectionId: connectionId,
        nangoIntegrationId: providerConfigKey,
      },
      {
        onError() {
          notifications.show({
            color: "red",
            icon: <IconBug size={18} />,
            message:
              errorMessage ??
              `There was an error connecting to your ${connector} platform, please try again later.`,
          });
        },
        async onSettled() {
          setIsSyncing(false);
        },
      },
    );
  }

  return {
    onOpenConnect,
    isSyncing,
    isConnecting,
  };
}
