import { z } from "zod";
import { zodJsonStringAsDate } from "../utils/schema";
import { CouponSchema } from "./Coupon";
import { IdentitySchema } from "./Identity";

export const defaultOrganizationStacks = ["foundations", "brand"];

export enum WidgetId {
  Build = "build",
  Create = "create",
  News = "news",
  Tasks = "tasks",
  Team = "team",
  Sales = "sales",
  SitePerformance = "site-performance",
  ClientMode = "client-mode",
}

export const fixedWidgets = [WidgetId.Build, WidgetId.Create];
export const flexibleWidgetIds = [
  WidgetId.Tasks,
  WidgetId.Team,
  WidgetId.News,
  WidgetId.Sales,
  WidgetId.SitePerformance,
  WidgetId.ClientMode,
];

export enum TargetType {
  B2B = "B2B",
  B2C = "B2C",
}

export enum BusinessType {
  Solo = "Solo",
  Team = "Team",

  /** @deprecated */
  Agency = "Agency",
}

export enum BusinessIndustryStack {
  Coaching = "Coaching",
  SportsTeam = "Sports Team",
  Manufacturing = "Manufacturing",
  Consulting = "Consulting",
  Education = "Education",
  Farm = "Farm",
  Accommodation = "Accommodation",
  Restaurant = "Restaurant",
  Retail = "Retail",
  Startup = "Startup",
  Gym = "Gym",
  ClimbingGym = "Climbing Gym",
  Ecomm = "Ecomm",
  Travel = "Travel",
  Services = "Services",
  Architecture = "Architecture",
  Beverage = "Beverage",
  Food = "Food",
  RealEstate = "Real Estate",
  Dentist = "Dentist",
  Doctor = "Doctor",
  Charity = "Charity",
}

export enum BusinessGoals {
  CreateContent = "Create content",
  DevelopMarketingMaterials = "Develop marketing materials",
  BuildSubscriptionModel = "Build a subscription model",
  DevelopOurBrand = "Develop our brand",
  BuildATeam = "Build a team",
  DevelopAVision = "Develop a vision",
}

export enum TeamSize {
  One = 1,
  Small = 9, // 2-9
  Medium = 49, // 10-49
  Big = 249, // 50-249
  Huge = 2500, // 250-2500
}

export enum Industry {
  Healthcare = "Health and Wellness",
  FinanceAndBanking = "Finance and Banking",
  Education = "Education",
  RetailAndECommerce = "Retail, DTC, and E-commerce",
  RealEstate = "Real Estate",
  Manufacturing = "Manufacturing",
  HospitalityAndTravel = "Hospitality and Travel",
  MediaAndEntertainment = "Media and Entertainment",
  Tech = "Technology and IT Services",
  Logistics = "Logistics and Supply Chain",
  ProfessionalServices = "Professional Services",
  Other = "Other",
}

export enum BrandTone {
  Neutral = "neutral",
  Playful = "playful",
  Professional = "professional",
  Informative = "informative",
  Authorative = "authorative",
  Humourous = "humourous",
}

/**
 * List of valid file types for image upload.
 */
export const supportedLogoMimeTypes = [
  "image/png", // .png
  "image/jpeg", // .jpeg
] as const;

export const BlockPathConfigurationSchema = z.object({
  enabled: z.boolean().default(true),
});

export const LogoSchema = z.object({
  storagePath: z.string(),
  originalFileName: z.string(),
  uploadedAt: z.string(),
});

export const OrganizationSettingsSchema = z.object({
  brandTone: z.nativeEnum(BrandTone).optional(),

  attributes: z
    .object({
      newCompany: z.boolean().optional(),
      website: z.string().url().nullable().optional(),
      industry: z.nativeEnum(Industry).optional(),
      teamSize: z.nativeEnum(TeamSize).optional(),
      targetType: z.nativeEnum(TargetType).optional(),
      businessGoals: z.array(z.nativeEnum(BusinessGoals)).optional(),
      industryStacks: z.array(z.nativeEnum(BusinessIndustryStack)).optional(),
      businessType: z.nativeEnum(BusinessType).optional(),
    })
    .optional(),

  websiteScraper: z
    .object({
      // We don't really care about having these be real dates for our purposes.
      startedAt: z.string().optional(),
      finishedAt: z.string().optional().nullable(),
      website: z.string().url().optional(),
    })
    .optional(),

  creatorPrompts: z
    .object({
      /**
       * Prisma returns this as a string, since it's a JSON value and not a db column.
       * We need to pre-process it first:
       */
      generatedAt: zodJsonStringAsDate,
      suggestions: z.array(z.string()),
    })
    .optional(),

  /**
   * Stacks whose blocks are used in the context of the interaction
   * with the assistants or any kind of AI generation.
   */
  stacks: z.array(z.string()).optional(),

  /**
   * Tracks per-blockPath settings, e.g if a specific block is enabled/disabled.
   *
   * Blocks are enabled by default, including if not listed here.
   *
   * TODO: Move this to its own record, or even better, create a per-block record
   * for each organization to contain block information, as well as configuration.
   *
   * Maps blockPath -> blockPath options
   */
  blockPaths: z
    .record(z.string(), BlockPathConfigurationSchema)
    .default({})
    .optional(),

  /**
   * Widgets displayed in the dashboard
   */
  widgets: z.array(z.nativeEnum(WidgetId)).optional(),

  /**
   * Tracks the status of the Client Mode onboarding.
   *
   * This is mainly to keep the state when the onboarding was completed for first
   * time.
   */
  clientMode: z
    .object({
      isOnboardingCompleted: z.boolean().optional(),
      colorPrimary: z.string().optional(),
      colorSecondary: z.string().optional(),
      logo: LogoSchema.optional(),
    })
    .optional(),
});

export const OrganizationSchema = z.object({
  id: z.string().uuid(),
  slug: z.string().min(3).max(64),
  name: z.string().min(3).max(64),
  settings: OrganizationSettingsSchema.default({}),
});

export const OrganizationWithIdentitiesSchema = OrganizationSchema.merge(
  z.object({
    coupons: z.array(CouponSchema).optional(),
    identities: z.array(IdentitySchema),
    clientLogoUrl: z.string().optional(),
  }),
);

export const CreateOrganizationSchema = OrganizationSchema.pick({
  name: true,
  settings: true,
});

export const UpdateOrganizationSchema = CreateOrganizationSchema.merge(
  z.object({
    name: CreateOrganizationSchema.shape.name.optional(),
    settings: CreateOrganizationSchema.shape.settings.optional(),
  }),
);

/**
 * Metadata required to start the upload procedure
 */
export const LogoUploadMetadataSchema = z.object({
  mimeType: z
    .string()
    .refine(
      (v) => (supportedLogoMimeTypes as unknown as string[]).includes(v),
      { message: "Not a valid input mime type" },
    ),
  fileName: z.string().max(1024),
});

export const CommitLogoSchema = LogoUploadMetadataSchema.merge(
  z.object({
    storagePath: z.string(),
  }),
);

export type Organization = z.infer<typeof OrganizationSchema>;
export type OrganizationWithIdentities = z.infer<
  typeof OrganizationWithIdentitiesSchema
>;

export type OrganizationSettings = z.infer<typeof OrganizationSettingsSchema>;

export type LogoUploadMetadata = z.infer<typeof LogoUploadMetadataSchema>;
export type CommitLogo = z.infer<typeof CommitLogoSchema>;
export type Logo = z.infer<typeof LogoSchema>;
