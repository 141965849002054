import { z } from "zod";
import { IdentityEmbedSchema } from "./Identity";

/**
 * List of valid file types for document upload, based on what our pipeline ingestion
 * can handle.
 */
export const supportedDocumentMimeTypes = [
  "text/plain", // .txt
  "application/pdf", // .pdf
  "text/markdown", // .md
  "text/html", // .html
  "application/epub+zip", // .epub
  "text/csv", // .csv
  "application/msword", // .doc
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
  "application/vnd.ms-excel", // .xls
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
  "application/vnd.ms-powerpoint", // .ppt
  "application/vnd.openxmlformats-officedocument.presentationml.presentation", // .pptx
] as const;

export enum DocumentStorageProvider {
  S3 = "S3",
}

export const DocumentSchema = z.object({
  id: z.string().uuid(),
  createdAt: z.date(),
  uploader: IdentityEmbedSchema,

  /**
   * Where the file is stored (e.g S3)
   */
  storageProvider: z.nativeEnum(DocumentStorageProvider),

  /**
   * Fully-qualified identifier to access this file in the given provider, e.g an S3 ARN
   */
  storagePath: z.string(),
  originalFileName: z.string(),
  mimeType: z
    .string()
    .refine(
      (v) => (supportedDocumentMimeTypes as unknown as string[]).includes(v),
      { message: "Not a valid input mime type" },
    ),

  /**
   * If available, a full text representation of the document
   */
  textContent: z.string().nullable(),

  /**
   * If available, a short generated summary of the document
   */
  contentSummary: z.string().nullable(),

  /**
   * If available, a user-provided description of the document
   */
  description: z.string().nullable(),
});

/**
 * Metadata required to actually start the upload procedure
 */
export const DocumentUploadMetadataSchema = z.object({
  mimeType: DocumentSchema.shape.mimeType,
  fileName: z.string().max(1024),
});

/**
 * After uploading, the client notifies us with the granted URL, which we validate
 * and use to create a Document record:
 */
export const CommitDocumentSchema = DocumentUploadMetadataSchema.merge(
  z.object({
    storagePath: z.string(),
  }),
);

export type DocumentUploadMetadata = z.infer<
  typeof DocumentUploadMetadataSchema
>;

export type CommitDocument = z.infer<typeof CommitDocumentSchema>;
export type Document = z.infer<typeof DocumentSchema>;
