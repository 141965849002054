import classes from "./StackIcons.module.css";

export function GolfIcon() {
  return (
    <svg
      width="205"
      height="230"
      viewBox="0 0 205 230"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.stackIcon}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.8548 140.261C79.2032 143.019 68.3843 152.68 68.3843 164.175C68.3843 177.818 83.6251 188.878 102.426 188.878C121.226 188.878 136.467 177.818 136.467 164.175C136.467 152.654 125.6 142.976 110.9 140.243L110.9 100.111L162.034 70.5887L93.854 31.2257L93.8548 140.261ZM118.451 164.268C118.467 164.212 118.467 164.19 118.467 164.175C118.467 164.16 118.467 164.138 118.451 164.081C118.433 164.019 118.382 163.865 118.241 163.623C117.948 163.121 117.274 162.254 115.925 161.275C114.542 160.272 112.673 159.321 110.397 158.621C109.213 161.905 106.07 164.253 102.377 164.253C98.6952 164.253 95.5584 161.918 94.3672 158.648C92.1311 159.345 90.2918 160.284 88.9268 161.275C87.5776 162.254 86.9035 163.121 86.6107 163.623C86.4695 163.865 86.4179 164.019 86.4003 164.081C86.3844 164.138 86.3843 164.16 86.3843 164.175C86.3843 164.19 86.3844 164.212 86.4003 164.268C86.4179 164.331 86.4695 164.484 86.6107 164.727C86.9035 165.229 87.5776 166.096 88.9268 167.075C91.6933 169.082 96.4077 170.878 102.426 170.878C108.444 170.878 113.158 169.082 115.925 167.075C117.274 166.096 117.948 165.229 118.241 164.727C118.382 164.484 118.433 164.331 118.451 164.268Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M204.537 69.724L204.536 154.274C204.536 166.453 198.038 177.707 187.491 183.796L114.268 226.072C106.842 230.359 97.6937 230.359 90.2681 226.072L12 180.884C4.57443 176.597 6.84045e-05 168.674 4.77098e-05 160.099L0 75.548C2.01768e-05 63.3691 6.49753 52.1153 17.0448 46.0259L90.269 3.7511C97.6945 -0.536001 106.843 -0.536013 114.269 3.75107L192.537 48.9394C199.963 53.2266 204.537 61.1497 204.537 69.724ZM21.0448 63.398L98.2688 18.8138C100.744 17.3848 103.794 17.3848 106.269 18.8138L183.492 63.3988C185.967 64.8279 187.492 67.469 187.492 70.3271L187.491 159.496C187.491 162.354 185.966 164.995 183.491 166.424L106.268 211.009C103.793 212.438 100.743 212.438 98.2683 211.009L21.0447 166.424C18.5695 164.995 17.0448 162.354 17.0448 159.496L17.0447 70.3262C17.0447 67.4681 18.5695 64.827 21.0448 63.398Z"
        fill="currentColor"
      />
    </svg>
  );
}
