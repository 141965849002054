import { ActionIcon, MantineSize, Tooltip } from "@mantine/core";
import { IconLock, IconLockOpen } from "@tabler/icons-react";

export function LockActionIcon({
  isLocked,
  lockedTooltip = "Only you can see this",
  unlockedTooltip = "People in your organization can see this",
  iconProps = {},
  onClick,
}: {
  isLocked: boolean;
  lockedTooltip?: string;
  unlockedTooltip?: string;
  onClick: () => void;
  iconProps?: { size?: MantineSize };
}) {
  return (
    <Tooltip label={isLocked ? lockedTooltip : unlockedTooltip}>
      <ActionIcon
        size="sm"
        color={isLocked ? "yellow" : "dark"}
        variant="transparent"
        onClick={onClick}
      >
        {isLocked ? (
          <IconLock size={iconProps.size} />
        ) : (
          <IconLockOpen size={iconProps.size} />
        )}
      </ActionIcon>
    </Tooltip>
  );
}
