import {
  Anchor,
  Badge,
  Button,
  Group,
  Text,
  ThemeIcon,
  Title,
  Tooltip,
} from "@mantine/core";
import { IconCheck, IconInfoCircle } from "@tabler/icons-react";
import { WidgetId } from "core";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useIsGranted } from "../../../auth/useIsGranted";
import { useClientMode } from "../../../utils/useClientMode";
import { useDashboard } from "../useDashboard";
import { BaseWidget, QuickLinkButton } from "./BaseWidget";
import { widgetProps } from "./config";

function ClientModeStatusDetails({
  isAllCompleted,
  isNoneCompleted,
  completed,
  total,
}: {
  isAllCompleted: boolean;
  isNoneCompleted: boolean;
  completed: number;
  total: number;
}) {
  function completionProps() {
    if (isAllCompleted)
      return {
        text: "Completed",
        tooltip: "All the Smart Blocks set for clients are completed.",
      };
    if (isNoneCompleted)
      return {
        text: "Not started",
        color: "base.6",
      };
    return {
      text: "In progress",
      color: "yellow",
      tooltip: "Some of the Smart Blocks set for clients are completed.",
    };
  }

  return (
    <Group align="center" justify="space-between">
      <Tooltip
        label={completionProps().tooltip}
        disabled={!completionProps().tooltip}
      >
        <Badge
          size="lg"
          color={completionProps().color}
          leftSection={isAllCompleted ? <IconCheck size={16} /> : undefined}
        >
          {completionProps().text}
        </Badge>
      </Tooltip>
      <Tooltip label="Smart Blocks set for clients completed">
        <Title order={4} fw="bold">
          {completed}/{total}
        </Title>
      </Tooltip>
    </Group>
  );
}

export function ClientModeWidget() {
  const navigate = useNavigate();

  const { hasPermission } = useIsGranted({
    permission: "widget.read.client-mode",
  });
  const {
    isClient,
    allBlockPathsCompleted,
    clientBlocks,
    nextIncompleteBlock,
    isAllCompleted,
    isNoneCompleted,
  } = useClientMode();
  const { orgStacks } = useDashboard();

  if (!hasPermission) return null;

  const nextIncompleteBlockIndex = orgStacks
    .flatMap((stack) => stack.blocks)
    .findIndex((b) => b.knowledgeslug! === nextIncompleteBlock);

  function openOnboarding() {
    navigate({
      pathname: "/client-onboarding",
      search: createSearchParams({
        ...(nextIncompleteBlockIndex !== -1 && {
          _step: nextIncompleteBlockIndex.toString(),
        }),
      }).toString(),
    });
  }

  return (
    <BaseWidget
      title={
        isClient ? "Business Details" : widgetProps[WidgetId.ClientMode].label
      }
      disabled={!isClient}
    >
      {isClient ? (
        <>
          <Text size="sm" c="dimmed">
            Add details about your business so we can start building together.
            You can update these at any time.
          </Text>
          <ClientModeStatusDetails
            isAllCompleted={isAllCompleted}
            isNoneCompleted={isNoneCompleted}
            completed={allBlockPathsCompleted.length}
            total={clientBlocks.length}
          />
          {isAllCompleted ? (
            <>
              <Text c="dimmed">
                You've completed all the information assigned!
              </Text>
              <Button variant="outline" size="md" onClick={openOnboarding}>
                Review details
              </Button>
            </>
          ) : (
            <QuickLinkButton
              label={`${isNoneCompleted ? "Begin" : "Complete"} onboarding.`}
              onClick={openOnboarding}
            />
          )}
        </>
      ) : (
        <>
          <Group gap="xs" wrap="nowrap">
            <ThemeIcon c="dimmed" variant="transparent" size="sm">
              <IconInfoCircle />
            </ThemeIcon>
            <Text c="dimmed" size="sm">
              The widget is active for members with the <b>client</b> role. To
              customize it, configure the{" "}
              <Anchor href="/account/organization/team">Team</Anchor> and{" "}
              <Anchor href="/account/organization/blocks">Blocks</Anchor>{" "}
              sections.
            </Text>
          </Group>
          <ClientModeStatusDetails
            isAllCompleted={isAllCompleted}
            isNoneCompleted={isNoneCompleted}
            completed={allBlockPathsCompleted.length}
            total={clientBlocks.length}
          />
        </>
      )}
    </BaseWidget>
  );
}
