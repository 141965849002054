import { IdentityRole } from "core";
import { useMemo } from "react";
import { useAuthContext } from "../auth/useAuthContext";
import { trpc } from "./trpc";

function useOrganizationById(id: string = "") {
  const { user } = useAuthContext();
  const { data, ...rest } = trpc.organization.getById.useQuery(
    { id },
    { enabled: !!id },
  );

  return {
    data: data
      ? {
          ...data,
          currentUserIdentity: data.identities.find(
            (i) => i.authUserId === user.id,
          ),
          members: data.identities.filter(
            (identity) => identity.primaryRole !== IdentityRole.Client,
          ),
          clients: data.identities.filter(
            (identity) => identity.primaryRole === IdentityRole.Client,
          ),
        }
      : undefined,
    ...rest,
  };
}

export function useOrganization() {
  const { identity } = useAuthContext();
  const org = useOrganizationById(identity?.organizationId);

  return {
    ...org,
    organization: org.data,
  };
}

export function useClaimCoupon() {
  const utils = trpc.useUtils();

  return trpc.organization.claimCoupon.useMutation({
    onSettled() {
      utils.organization.getById.invalidate();
    },
  });
}

export function useOrganizationStacks() {
  const { organization, isLoading, isRefetching } = useOrganization();

  const stacks = useMemo(
    () => organization?.settings.stacks || [],
    [organization?.settings.stacks],
  );

  return {
    stacks,
    isLoadingStacks: isLoading,
    isRefetchingStacks: isRefetching,
  };
}

export function useLogoUpload() {
  const utils = trpc.useUtils();

  const {
    data: logoUploadURL,
    mutate: createLogoUploadURL,
    isLoading: isCreatingLogoUploadURL,
    isError: isLogoUploadURLError,
  } = trpc.organization.createLogoUploadURL.useMutation();

  const {
    mutate: commitLogo,
    isLoading: isCommittingLogo,
    isError: isCommitLogoError,
    data: committedLogo,
  } = trpc.organization.commitLogo.useMutation({
    onSettled() {
      utils.organization.getById.invalidate();
      utils.organization.getAll.invalidate();
    },
  });

  return {
    logoUploadURL,
    createLogoUploadURL,
    isCreatingLogoUploadURL,
    isLogoUploadURLError,

    commitLogo,
    isCommittingLogo,
    isCommitLogoError,
    committedLogo,
  };
}
export function useDeleteLogo() {
  const utils = trpc.useUtils();

  const { mutate: deleteLogo, isLoading: isDeletingLogo } =
    trpc.organization.deleteLogo.useMutation({
      onSettled() {
        utils.organization.getById.invalidate();
      },
    });

  return {
    deleteLogo,
    isDeletingLogo,
  };
}
