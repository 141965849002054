import { Button, Group, Text } from "@mantine/core";
import { WidgetId } from "core";
import { useNavigate } from "react-router-dom";
import { useClientMode } from "../../../utils/useClientMode";
import { useDashboard } from "../useDashboard";
import { FixedWidget, QuickLinkButton } from "./BaseWidget";
import { widgetProps } from "./config";
import classes from "./CreateWidget.module.css";

export function CreateWidget() {
  const navigate = useNavigate();

  const { stats } = useDashboard();
  const { isClient, isCreateModeEnabled } = useClientMode();

  const hasAssets = stats.topBuckets.length > 0;

  if (!isCreateModeEnabled) return null;

  return (
    <FixedWidget
      title={widgetProps[WidgetId.Create].label}
      redirectTo="/create/all"
    >
      <Text size="sm" c="dimmed">
        {hasAssets
          ? "Top created content categories"
          : "Create content to power your business."}
      </Text>
      {hasAssets ? (
        <Group gap="xs">
          {stats.topBuckets.map(({ bucket }) => (
            <Button
              key={bucket}
              variant="default"
              size="md"
              onClick={() => navigate(`/create/all?tag=${bucket}`)}
              className={classes.bucketButton}
            >
              {bucket}
            </Button>
          ))}
        </Group>
      ) : (
        <QuickLinkButton
          label={
            isClient
              ? "Start creating copy for your new website in Create Mode"
              : "Start building in Build Mode to unlock Create Mode."
          }
          onClick={() => navigate("/create")}
        />
      )}
    </FixedWidget>
  );
}
