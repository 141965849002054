import { useDisclosure, useLocalStorage } from "@mantine/hooks";
import { IconStack2 } from "@tabler/icons-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FeatureUnlockedModal } from ".";
import { useIsPreviewMode } from "../../utils/useAccount";
import { useIsStackCompleted } from "../../utils/useStacks";

export function useExtraStackUnlockedNotification() {
  const navigate = useNavigate();
  const isPreviewMode = useIsPreviewMode();
  const isFoundationsStackComplete = useIsStackCompleted("foundations");
  const [
    notifAddStackUnlockedOpened,
    { open: openNotifAddStackUnlocked, close: closeNotifAddStackUnlocked },
  ] = useDisclosure(false);
  const [extraStackUnlocked, setExtraStackUnlocked] = useLocalStorage({
    key: "noan-extra-stack-unlocked",
    defaultValue: false,
    getInitialValueInEffect: false,
  });

  useEffect(() => {
    if (isFoundationsStackComplete && !extraStackUnlocked) {
      setExtraStackUnlocked(true);
      openNotifAddStackUnlocked();
    }
  }, [
    extraStackUnlocked,
    isFoundationsStackComplete,
    openNotifAddStackUnlocked,
    setExtraStackUnlocked,
  ]);

  return {
    isUnlocked: extraStackUnlocked,
    notification: isPreviewMode ? (
      <FeatureUnlockedModal
        title="You've unlocked an extra stack."
        description="Expand your business knowledge by adding a new stack."
        actionLabel="Add stack"
        icon={<IconStack2 size={40} />}
        isOpened={notifAddStackUnlockedOpened}
        onClose={closeNotifAddStackUnlocked}
        onAction={() => {
          navigate("/build/stacks");
        }}
      />
    ) : null,
  };
}
