import classes from "./StackIcons.module.css";

export function ClimbingGymIcon() {
  return (
    <svg
      width="205"
      height="230"
      viewBox="0 0 205 230"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.stackIcon}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M143.338 104.387L90.7612 74.0316C76.6198 65.8671 58.5372 70.7123 50.3727 84.8537C42.2081 98.9953 47.0533 117.078 61.1947 125.242L52.1947 140.831C29.444 127.696 21.6491 98.6044 34.7842 75.8537C47.9193 53.1032 77.0105 45.308 99.7612 58.4433L152.338 88.7987C175.089 101.934 182.884 131.025 169.749 153.776C156.614 176.526 126.752 183.795 104.001 170.66C99.6964 168.175 98.2214 162.671 100.707 158.366C103.192 154.061 108.696 152.586 113.001 155.072C127.142 163.236 145.996 158.917 154.16 144.776C162.325 130.634 157.48 112.552 143.338 104.387ZM108.751 168.962C112.065 168.962 114.751 166.275 114.751 162.962C114.751 159.648 112.065 156.962 108.751 156.962C105.437 156.962 102.751 159.648 102.751 162.962C102.751 166.275 105.437 168.962 108.751 168.962Z"
        fill="currentColor"
      />
      <path
        d="M99.5063 101.032H117.506V154.227L114.596 152.513C110.838 150.3 106.175 150.3 102.417 152.513L99.5063 154.227V101.032Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M204.537 154.023L204.538 69.4725C204.538 60.8981 199.964 52.9751 192.538 48.6879L114.27 3.49955C106.844 -0.787539 97.6955 -0.787527 90.2699 3.49958L17.0458 45.7744C6.4985 51.8638 0.000996739 63.1176 0.000976562 75.2965L0.00102427 159.848C0.00104497 168.422 4.57541 176.345 12.001 180.632L90.269 225.82C97.6946 230.108 106.843 230.108 114.269 225.82L187.492 183.545C198.039 177.455 204.537 166.202 204.537 154.023ZM98.2698 18.5623L21.0457 63.1465C18.5705 64.5755 17.0457 67.2165 17.0457 70.0747L17.0457 159.245C17.0457 162.103 18.5705 164.744 21.0457 166.173L98.2692 210.758C100.744 212.187 103.794 212.187 106.269 210.758L183.492 166.173C185.967 164.744 187.492 162.103 187.492 159.245L187.493 70.0756C187.493 67.2174 185.968 64.5764 183.493 63.1473L106.27 18.5623C103.795 17.1333 100.745 17.1332 98.2698 18.5623Z"
        fill="currentColor"
      />
    </svg>
  );
}
