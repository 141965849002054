import {
  Card,
  Divider,
  SimpleGrid,
  Skeleton,
  Stack,
  Text,
} from "@mantine/core";
import clsx from "clsx";
import { Connector, isConnectorGoogleAnalytics, WidgetId } from "core";
import { useIsGranted } from "../../../auth/useIsGranted";
import { usePlatformConnector } from "../../../components/PlatformConnector/usePlatformConnector";
import { RestrictedFeature } from "../../../components/RestrictedFeature";
import { useGetConnection } from "../../../utils/useConnections";
import { AccountSelector } from "../../SitePerformance/AccountSelector";
import { LatestTrendsView } from "../../SitePerformance/LatestTrendsView";
import { BaseWidget, QuickLinkButton } from "./BaseWidget";
import baseWidgetClasses from "./BaseWidget.module.css";
import { widgetProps } from "./config";

function LoadingPlaceholder() {
  return (
    <Stack>
      <Card bg="dark">
        <Stack>
          <Stack gap="xs">
            <Skeleton w="45%" h={20} />
            <Skeleton w="20%" h={10} />
          </Stack>
          <SimpleGrid cols={2}>
            <Stack gap="xs">
              <Skeleton w="50%" h={20} />
              <Skeleton w="40%" h={15} />
            </Stack>
            <Stack gap="xs">
              <Skeleton w="60%" h={20} />
              <Skeleton w="50%" h={15} />
            </Stack>
            <Stack gap="xs">
              <Skeleton w="70%" h={20} />
              <Skeleton w="40%" h={15} />
            </Stack>
          </SimpleGrid>
        </Stack>
      </Card>
      <Divider />
      <Stack>
        <Stack gap="xs">
          <Skeleton w="45%" h={20} />
        </Stack>
        <SimpleGrid cols={2}>
          <Stack gap="xs">
            <Skeleton w="50%" h={20} />
            <Skeleton w="40%" h={15} />
          </Stack>
          <Stack gap="xs">
            <Skeleton w="60%" h={20} />
            <Skeleton w="50%" h={15} />
          </Stack>
          <Stack gap="xs">
            <Skeleton w="70%" h={20} />
            <Skeleton w="40%" h={15} />
          </Stack>
        </SimpleGrid>
      </Stack>
    </Stack>
  );
}

export function SitePerformanceWidget() {
  const { onOpenConnect, isSyncing, isConnecting } = usePlatformConnector({
    connector: Connector.GoogleAnalytics,
  });
  const { connection, isGettingConnection } = useGetConnection({
    connector: Connector.GoogleAnalytics,
  });
  const { hasPermission } = useIsGranted({ permission: "connection.read" });

  const isLoading = isSyncing || isGettingConnection;
  const showInitialState = !connection;

  const isAccountConnected =
    isConnectorGoogleAnalytics(connection?.configuration) &&
    !!connection.configuration?.connectedAccount?.propertyName;

  if (!hasPermission) return null;

  return (
    <BaseWidget
      title={widgetProps[WidgetId.SitePerformance].label}
      redirectTo="/site"
      cardClassName={clsx(
        showInitialState && baseWidgetClasses.fixedWidgetCard,
      )}
    >
      {isLoading ? (
        <LoadingPlaceholder />
      ) : (
        <RestrictedFeature minimize>
          {({ restrictedClassName }) => (
            <Stack>
              {showInitialState ? (
                <>
                  <Text size="sm" c="dimmed">
                    Connect your Google Analytics platform to monitor user
                    interactions and gain insights into your website or app
                    performance.
                  </Text>
                  <QuickLinkButton
                    label="Connect your Google Analytics platform"
                    className={restrictedClassName}
                    isLoading={isConnecting}
                    onClick={onOpenConnect}
                  />
                </>
              ) : isAccountConnected ? (
                <LatestTrendsView
                  contentLoadingPlaceholder={<LoadingPlaceholder />}
                />
              ) : (
                <AccountSelector configuration={connection.configuration} />
              )}
            </Stack>
          )}
        </RestrictedFeature>
      )}
    </BaseWidget>
  );
}
