import classes from "./StackIcons.module.css";

export function StorageIcon() {
  return (
    <svg
      width="205"
      height="230"
      viewBox="0 0 205 230"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.stackIcon}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M83.5732 142.742L83.4912 104.089L117.015 84.7496V67.691L68.7315 39.8158L20.4517 67.6897V123.442L68.7324 151.317L83.5732 142.742ZM68.7328 54.5494L89.492 66.5348L68.7322 78.5218L47.9726 66.5356L68.7328 54.5494ZM33.212 92.102L53.9724 104.088L53.9721 128.061L34.206 116.648L33.2113 116.069L33.212 92.102Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M87.4961 106.396L87.6143 162.098L135.899 189.975L184.181 162.099L184.18 106.348L135.899 78.4736L87.4961 106.396ZM135.899 93.2079L156.659 105.194L135.898 117.18L115.139 105.193L135.899 93.2079ZM100.377 130.76L121.138 142.746L121.138 166.718L100.377 154.733L100.377 130.76ZM171.42 130.76L171.42 153.691L171.292 154.807L150.658 166.717V142.747L171.42 130.76Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M204.536 154.274L204.537 69.724C204.537 61.1497 199.963 53.2266 192.537 48.9394L114.269 3.75107C106.843 -0.536013 97.6945 -0.536001 90.269 3.7511L17.0448 46.0259C6.49753 52.1153 2.01768e-05 63.3691 0 75.548L4.77098e-05 160.099C6.84045e-05 168.674 4.57443 176.597 12 180.884L90.2681 226.072C97.6937 230.359 106.842 230.359 114.268 226.072L187.491 183.796C198.038 177.707 204.536 166.453 204.536 154.274ZM98.2688 18.8138L21.0448 63.398C18.5695 64.827 17.0447 67.4681 17.0447 70.3262L17.0448 159.496C17.0448 162.354 18.5695 164.995 21.0447 166.424L98.2683 211.009C100.743 212.438 103.793 212.438 106.268 211.009L183.491 166.424C185.966 164.995 187.491 162.354 187.491 159.496L187.492 70.3271C187.492 67.469 185.967 64.8279 183.492 63.3988L106.269 18.8138C103.794 17.3848 100.744 17.3848 98.2688 18.8138Z"
        fill="currentColor"
      />
    </svg>
  );
}
