import { Group, Progress, Stack, Text } from "@mantine/core";
import { WidgetId } from "core";
import { Fragment, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useIsGranted } from "../../../auth/useIsGranted";
import { StackBadge } from "../../../components/badges/StackBadge";
import { StackKey, stackStyle } from "../../../components/icons/stacks";
import { useTheme } from "../../../components/ThemeProvider/useTheme";
import { arrayWithNElements } from "../../../utils/arrays";
import { useDashboard } from "../useDashboard";
import { FixedWidget, QuickLinkButton } from "./BaseWidget";
import { widgetProps } from "./config";

export function BuildWidget() {
  const navigate = useNavigate();
  const { orgStacks, facts } = useDashboard();
  const { hasPermission } = useIsGranted({ permission: "widget.read.build" });
  const { theme } = useTheme();

  const allBlockPathsCompleted = useMemo(
    () => facts.flatMap((f) => f.blockPath),
    [facts],
  );
  const incompleteStacks = useMemo(
    () =>
      orgStacks
        ?.filter((stack) =>
          stack.blocks.some(
            (b) => !allBlockPathsCompleted.includes(b.knowledgeslug!),
          ),
        )
        .map((stack) => {
          const totalCompletedBlocks =
            stack?.blocks.reduce(
              (prev, cur) =>
                allBlockPathsCompleted.includes(cur.knowledgeslug!)
                  ? prev + 1
                  : prev,
              0,
            ) ?? 0;
          const firstIncompleteBlock = stack?.blocks.find(
            (b) => !allBlockPathsCompleted.includes(b.knowledgeslug!),
          );

          return {
            ...stack,
            stackSlug: stack.stack.slug,
            stackName: stack.stack.name,
            totalBlocks: stack.blocks.length,
            totalCompletedBlocks,
            firstIncompleteBlock,
          };
        }),
    [allBlockPathsCompleted, orgStacks],
  );

  if (!hasPermission) return null;
  if (incompleteStacks.length === 0) return null;

  return (
    <FixedWidget
      title={widgetProps[WidgetId.Build].label}
      redirectTo={`/build`}
    >
      {incompleteStacks.map((incompleteStack) => (
        <Fragment key={incompleteStack.stackSlug}>
          <Stack gap="xs">
            <Group justify="space-between">
              <StackBadge
                name={incompleteStack.stackName}
                icon={
                  stackStyle[
                    incompleteStack.stackSlug.toLowerCase() as StackKey
                  ]?.icon
                }
                tt="uppercase"
                onClick={() =>
                  navigate(`/build/s/${incompleteStack.stackSlug}`)
                }
              />
              <Text fw="bold" size="lg">
                {incompleteStack.totalCompletedBlocks}/
                {incompleteStack.totalBlocks}
              </Text>
            </Group>
            <Group grow gap={3}>
              {arrayWithNElements(incompleteStack.totalBlocks).map((idx) => (
                <Progress
                  styles={{
                    section: {
                      background: theme.colors[theme.secondaryColor][3],
                    },
                  }}
                  key={idx}
                  size="sm"
                  value={incompleteStack.totalCompletedBlocks >= idx ? 100 : 0}
                />
              ))}
            </Group>
          </Stack>
          {incompleteStack.firstIncompleteBlock && (
            <QuickLinkButton
              label={`Complete ${incompleteStack.firstIncompleteBlock.title} block.`}
              onClick={() =>
                navigate(
                  `build/b/${incompleteStack.firstIncompleteBlock?.knowledgeslug}`,
                )
              }
            />
          )}
        </Fragment>
      ))}
    </FixedWidget>
  );
}
