/**
 * Calculates the percentage change between two values (e.g. previous and current monthly sales)
 * @param previousValue
 * @param currentValue
 * @returns
 */
export function calculatePercentageChange(
  previousValue = 0,
  currentValue = 0,
): number {
  if (previousValue === 0) return currentValue > 0 ? Infinity : -Infinity; // Handle division by zero
  return ((currentValue - previousValue) / previousValue) * 100;
}

export function formatAmount(amount: number, currency?: string) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: (currency && currency !== "n/a" ? currency : "usd").toUpperCase(),
    currencyDisplay: "symbol",
  });

  return formatter.format(amount);
}
