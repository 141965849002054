import { Badge, StyleProp, ThemeIcon } from "@mantine/core";
import { IconHexagon } from "@tabler/icons-react";
import clsx from "clsx";
import classes from "./StackBadge.module.css";

export function StackBadge({
  name,
  icon,
  tt,
  onClick,
}: {
  name: string;
  icon?: JSX.Element;
  tt?: StyleProp<React.CSSProperties["textTransform"]>;
  onClick?: () => void;
}) {
  return (
    <Badge
      variant="filled"
      fw="normal"
      size="lg"
      color="var(--mantine-primary-color-0)"
      radius="sm"
      styles={{
        root: { borderRadius: 4 },
      }}
      classNames={{
        root: clsx(!!onClick && classes.clickable),
      }}
      c="base.11"
      tt={tt ?? "none"}
      leftSection={
        <ThemeIcon size={18} variant="transparent" color="light">
          {icon ?? <IconHexagon />}
        </ThemeIcon>
      }
      onClick={onClick}
    >
      {name}
    </Badge>
  );
}
