import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

type DateLike = string | number | Date | dayjs.Dayjs;

export function relativeTimeFromNow(date: DateLike) {
  return dayjs(date).from(Date.now());
}

export function dateIsToday(date: DateLike) {
  return dayjs(date).isSame(Date.now(), "day");
}

export function formatDateTime(date: DateLike) {
  return dayjs(date).toString();
}

export function formatDate(date: DateLike) {
  return dayjs(date).format("MMM D, YYYY");
}

/**
 * Converts a time duration to milliseconds. Convenient for use with things like
 * timers or cache values.
 */
export function toMilliseconds({
  hours = 0,
  minutes = 0,
  seconds = 0,
}: {
  hours?: number;
  minutes?: number;
  seconds?: number;
}) {
  return (hours * 60 * 60 + minutes * 60 + seconds) * 1000;
}
