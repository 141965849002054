import { Carousel } from "@mantine/carousel";
import {
  Anchor,
  Skeleton,
  Spoiler,
  Stack,
  Text,
  ThemeIcon,
} from "@mantine/core";
import { IconArrowLeft, IconArrowRight } from "@tabler/icons-react";
import clsx from "clsx";
import { FeedScope, WidgetId } from "core";
import { Link, useNavigate } from "react-router-dom";
import { MarkdownContent } from "../../../components/MarkdownContent";
import { useTheme } from "../../../components/ThemeProvider/useTheme";
import { arrayWithNElements } from "../../../utils/arrays";
import { formatDate } from "../../../utils/date";
import { useClientMode } from "../../../utils/useClientMode";
import { useFeedEntries } from "../../../utils/useFeedEntries";
import { useDashboard } from "../useDashboard";
import { BaseWidget, QuickLinkButton } from "./BaseWidget";
import baseWidgetClasses from "./BaseWidget.module.css";
import classes from "./NewsWidget.module.css";
import { widgetProps } from "./config";

function LoadingPlaceholder() {
  return (
    <BaseWidget title={widgetProps[WidgetId.News].label}>
      <Carousel
        loop
        controlsOffset={0}
        withIndicators
        withControls={false}
        classNames={{
          indicator: classes.carouselIndicator,
          indicators: classes.carouselIndicators,
          controls: classes.carouselControls,
        }}
      >
        {arrayWithNElements(3).map((idx) => (
          <Carousel.Slide key={idx}>
            <Stack gap="xl">
              <Skeleton h={12} w="50%" />
              <Stack gap="xs">
                <Skeleton h={10} />
                <Skeleton h={10} w="80%" />
                <Skeleton h={10} w="90%" />
                <Skeleton h={10} w="75%" />
                <Skeleton h={10} w="50%" />
                <Skeleton h={10} w="80%" />
                <Skeleton h={10} />
              </Stack>
              <Skeleton h={10} w="35%" mb="xl" />
            </Stack>
          </Carousel.Slide>
        ))}
      </Carousel>
    </BaseWidget>
  );
}

export function NewsWidget() {
  const navigate = useNavigate();

  const { theme } = useTheme();
  const { isNewsEnabled } = useClientMode();
  const { isLoadingDashboard } = useDashboard();
  const { feed, isLoadingFeed } = useFeedEntries({ scope: FeedScope.News });

  const news = feed.slice(0, 10).sort((a, b) => {
    if (a.publishedAt < b.publishedAt) return 1;
    if (a.publishedAt > b.publishedAt) return -1;
    return 0;
  });
  const hasNews = news.length > 0;

  if (!isNewsEnabled) return null;
  if (isLoadingFeed || isLoadingDashboard) return <LoadingPlaceholder />;

  return (
    <BaseWidget
      title={widgetProps[WidgetId.News].label}
      cardClassName={clsx(!hasNews && baseWidgetClasses.fixedWidgetCard)}
    >
      {hasNews ? (
        <Carousel
          withIndicators
          withControls
          controlsOffset={0}
          nextControlIcon={
            <ThemeIcon variant="default" radius="xl">
              <IconArrowRight size={16} />
            </ThemeIcon>
          }
          previousControlIcon={
            <ThemeIcon variant="default" radius="xl">
              <IconArrowLeft size={16} />
            </ThemeIcon>
          }
          classNames={{
            indicator: classes.carouselIndicator,
            indicators: classes.carouselIndicators,
            controls: classes.carouselControls,
            control: classes.carouselControl,
          }}
          styles={{
            indicator: {
              background: theme.secondaryColor,
            },
          }}
        >
          {news.map((n) => (
            <Carousel.Slide key={crypto.randomUUID()}>
              <Stack mb="xl">
                {n.source.title && (
                  <Text size="lg" fw="bold">
                    {n.source.title}
                  </Text>
                )}
                <Text size="sm">
                  {n.publishedAt && `Published ${formatDate(n.publishedAt)}`}
                </Text>

                <Spoiler
                  maxHeight={200}
                  showLabel="Show more"
                  hideLabel="Hide"
                  styles={{
                    control: {
                      marginTop: 10,
                      fontSize: 12,
                    },
                  }}
                >
                  <MarkdownContent content={n.content} />
                </Spoiler>

                {n.source.sourceUrl && (
                  <Anchor
                    component={Link}
                    to={n.source.sourceUrl}
                    size="sm"
                    c="dimmed"
                    target="_blank"
                  >
                    {n.source.author || n.source.sourceUrl}
                  </Anchor>
                )}
              </Stack>
            </Carousel.Slide>
          ))}
        </Carousel>
      ) : (
        <>
          <Text size="sm" c="dimmed">
            Complete your Smart Blocks to access tailored news from your
            business insights.
          </Text>
          <QuickLinkButton
            label={`Start building in Build mode to unlock news.`}
            onClick={() => navigate(`/build`)}
          />
        </>
      )}
    </BaseWidget>
  );
}
