import {
  Button,
  Card,
  Modal,
  Stack,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";

export function FeatureUnlockedModal({
  title,
  description,
  actionLabel,
  icon,
  isOpened,
  onClose,
  onAction,
}: {
  title: string;
  description?: JSX.Element | string;
  actionLabel: string;
  icon: JSX.Element;
  isOpened: boolean;
  onClose: () => void;
  onAction: () => void;
}) {
  return (
    <Modal
      size="md"
      withinPortal
      keepMounted={false}
      opened={isOpened}
      onClose={onClose}
      centered
    >
      <Stack align="center" ta="center">
        <Card radius="md" shadow="sm">
          <ThemeIcon size={100} variant="transparent">
            {icon}
          </ThemeIcon>
        </Card>
        <Stack align="center" gap={0}>
          <Title order={4} fw="bold">
            Great work!
          </Title>
          <Title order={4} fw="bold">
            {title}
          </Title>
        </Stack>
        {description && <Text c="dimmed">{description}</Text>}
        <Button my="lg" onClick={onAction}>
          {actionLabel}
        </Button>
      </Stack>
    </Modal>
  );
}
