import Nango from "@nangohq/frontend";
import { Connector, NangoIntegrationIds } from "core";
import { trpc } from "./trpc";

const nangoExpectedErrors = [
  "The authorization window was closed before the authorization flow was completed",
];

export function useGetNangoSessionToken() {
  return trpc.connection.createSessionToken.useMutation();
}

export async function openConnect({
  sessionToken,
  connector,
  onError,
}: {
  sessionToken: string;
  connector: Connector;
  onError: () => void;
}) {
  const nango = new Nango({ connectSessionToken: sessionToken });

  try {
    return await nango.auth(NangoIntegrationIds[connector], {
      detectClosedAuthWindow: true,
    });
  } catch (err) {
    onError();

    // For expected errors, we just return to avoid sending errors to Sentry
    if (
      err instanceof Error &&
      nangoExpectedErrors.some((exp) => err.message.includes(exp))
    ) {
      return;
    }
    throw new Error(`Error connecting to ${connector} - ${err}`);
  }
}

export function useGetGoogleAnalyticsAccounts() {
  const utils = trpc.useUtils();

  const [data, { isLoading, ...query }] =
    trpc.connection.getGAAccounts.useSuspenseQuery(undefined, {
      refetchInterval(accounts) {
        return !accounts?.length ? 2000 : 0;
      },
      onSettled() {
        utils.facts.getFactsStartingWith.invalidate();
        utils.convos.getConvos.invalidate();
      },
    });

  return {
    accounts: data ?? [],
    isGettingAccounts: isLoading,
    ...query,
  };
}

export function useGetGoogleAnalyticsReports() {
  const utils = trpc.useUtils();

  const [data, query] = trpc.connection.getGAReports.useSuspenseQuery(
    undefined,
    {
      refetchInterval(reports) {
        return !!reports && !Object.values(reports).every((rep) => rep.length)
          ? 3000
          : 0;
      },
      onSettled() {
        utils.convos.getConvos.invalidate();
      },
    },
  );

  return {
    reports: data,
    isGettingReports: query.isLoading,
    ...query,
  };
}
