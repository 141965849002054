import { Badge, MantineColor } from "@mantine/core";

export function EntityTypeBadge({
  label,
  color = "dark",
}: {
  label: string;
  color?: MantineColor;
}) {
  return (
    <Badge size="sm" tt="capitalize" variant="light" color={color}>
      {label}
    </Badge>
  );
}
