import { Notifications } from "@mantine/notifications";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { QueryClient, type Query } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { httpBatchLink, loggerLink } from "@trpc/client";
import { PostHogProvider } from "posthog-js/react";
import { Suspense, useMemo, useState } from "react";
import { RouterProvider } from "react-router-dom";
import * as superjson from "superjson";
import { AuthOptionalContext } from "./auth/AuthContext";
import { useSupabaseAuth } from "./auth/supabase";
import { useOptionalAuthContext } from "./auth/useAuthContext";
import { ConnectionStateMonitor } from "./components/ConnectionStateMonitor";
import { FullPageLoader } from "./components/FullPageLoader";
import "./components/Storyblok"; // Init storyblok
import { ThemeProvider } from "./components/ThemeProvider";
import { postHogApiKey, postHogOptions, trpcEndpoint } from "./config";
import { routeDefinitions } from "./pages";
import { createSentryBrowserRouter } from "./sentry";
import { trpc } from "./utils/trpc";

// Init page routes:
const router = createSentryBrowserRouter(routeDefinitions);

export function AppContainer() {
  const {
    user,
    signOut,
    isLoading: isLoadingUser,
    authError,
    jwtBearerToken,
    hasThirdPartyProvider,
    mustSetPassword,
    mustCompletePostSignup,
  } = useSupabaseAuth();

  return (
    <AuthOptionalContext.Provider
      value={{
        user: user!,
        signOut,
        isLoadingUser,
        authError,
        jwtBearerToken,
        hasThirdPartyProvider,
        mustSetPassword,
        mustCompletePostSignup,
      }}
    >
      <App />
    </AuthOptionalContext.Provider>
  );
}

export function App() {
  const { jwtBearerToken } = useOptionalAuthContext();
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            refetchInterval: false,
            cacheTime: 300000, // 5 minutes
          },
        },
      }),
  );

  const [persister] = useState(() =>
    createSyncStoragePersister({
      storage: window.localStorage,
    }),
  );

  const trpcClient = useMemo(
    () =>
      trpc.createClient({
        transformer: superjson,
        links: [
          ...(import.meta.env.VITE_TRPC_DEBUG === "true" ? [loggerLink()] : []),

          httpBatchLink({
            url: trpcEndpoint,

            fetch(url, options) {
              return fetch(url, { ...options, credentials: "include" });
            },
            headers() {
              return {
                ...(jwtBearerToken == null
                  ? {}
                  : {
                      Authorization: jwtBearerToken,
                    }),
              };
            },
          }),
        ],
      }),
    [jwtBearerToken],
  );

  return (
    <PostHogProvider apiKey={postHogApiKey} options={postHogOptions}>
      <Suspense fallback={<FullPageLoader />}>
        <trpc.Provider client={trpcClient} queryClient={queryClient}>
          <PersistQueryClientProvider
            client={queryClient}
            persistOptions={{
              persister,
              dehydrateOptions: {
                shouldDehydrateQuery: (query: Query) => {
                  return query.meta?.persist;
                },
              },
            }}
          >
            <ThemeProvider>
              <Notifications position="bottom-center" />
              <RouterProvider
                router={router}
                fallbackElement={<FullPageLoader />}
                future={{
                  v7_startTransition: true,
                }}
              />
              <ConnectionStateMonitor />
            </ThemeProvider>
            <ReactQueryDevtools position="bottom-right" />
          </PersistQueryClientProvider>
        </trpc.Provider>
      </Suspense>
    </PostHogProvider>
  );
}
