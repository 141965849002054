import { useStoryblokApi } from "@storyblok/react";
import { useQuery } from "@tanstack/react-query";
import { FactWithState, type Fact } from "core";
import {
  RobotGenerativeModuleStoryblok,
  RobotInputModuleStoryblok,
  RobotModuleStoryblok,
} from "../storyblok-types";
import { toMilliseconds } from "./date";
import { useFacts } from "./useFacts";

export type Module = RobotGenerativeModuleStoryblok | RobotInputModuleStoryblok;
export interface Pillar {
  name: string;
  uuid: string;
  slug: string;
  description?: string;
  content: {
    completionmessage: string;
    modules: Module[];
    advanced_block: boolean;
  } & Pick<RobotModuleStoryblok, "descriptive_summary">;
}

/**
 * Post-processed list of blocks, grouped by stack, potentially
 * with filtering applied. This differs from the Storyblok API object,
 * for example, by not allowing circular navigation of the blocks list
 * via the stack.
 */
export type BlocksGroupedByStack = {
  stack: Omit<Pillar, "content">;
  blocks: (Module & {
    stackName: string;
    stackSlug: string;
    fact?: Fact | FactWithState;
  })[];
};

export function isInputModule(mod: Module): mod is RobotInputModuleStoryblok {
  return mod.component === "robot-input-module";
}

export function isGenerativeModule(
  mod: Module,
): mod is RobotGenerativeModuleStoryblok {
  return mod.component === "robot-generative-module";
}

/**
 * Fetches and pre-processes stack and module data. Most of the pre-processing
 * is in terms of making our lives easier in code-land, but this also acts as
 * a logical place to handle data-inconsistencies, do feature flagging, etc.
 */
export function useStacks() {
  // We can't really rely on the useStoryblok hook here as far as I can tell,
  // so we instead use the API directly, and take the opportunity to also wrap
  // that into React Query
  const storyblok = useStoryblokApi();

  const query = useQuery(
    ["sb", "strategy:published"],
    async () => {
      return storyblok.getAll("cdn/stories", {
        starts_with: "strategy/",
        sort_by: "position:asc",
        version: "published",
      });
    },
    {
      staleTime: toMilliseconds({ seconds: 10 }),
      meta: {
        persist: true,
      },
    },
  );

  return {
    stacks: query.data,
    ...query,
  };
}

export function useIsStackCompleted(stackSlug: string) {
  const { stacks, isLoading: isLoadingStacks } = useStacks();
  const [facts, { isLoading: isLoadingFacts }] = useFacts();

  if (isLoadingStacks || isLoadingFacts || !stacks || !facts.length)
    return false;

  const stack = stacks.find((st: Pillar) => st.slug === stackSlug) as
    | Pillar
    | undefined;

  if (!stack) return false;

  const stackBlockPaths =
    stack.content.modules.flatMap((mod) => mod.knowledgeslug) ?? [];
  const stackFacts = facts.filter((fa) =>
    stackBlockPaths.includes(fa.blockPath),
  );

  return stackFacts.length === stackBlockPaths.length;
}

export function getBlockPath({
  blockPath,
  stacks,
}: {
  blockPath: string;
  stacks: BlocksGroupedByStack[];
}) {
  const block = stacks
    .flatMap((s) => s.blocks)
    .find(
      (b) =>
        b.knowledgeslug?.toLocaleLowerCase() === blockPath.toLocaleLowerCase(),
    );

  return {
    block,
    stackName: block?.stackName ?? "",
    stackSlug: block?.stackSlug ?? "",
    title: block?.title ?? "",
    fact: block?.fact,
  };
}
