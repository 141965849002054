import {
  Divider,
  Group,
  List,
  ScrollAreaAutosize,
  Skeleton,
  Stack,
  Text,
  ThemeIcon,
  Title,
  Tooltip,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import {
  IconBrandGoogleAnalytics,
  IconBug,
  IconCheck,
} from "@tabler/icons-react";
import {
  Connector,
  ConnectorConfiguration,
  GoogleAnalyticsConnectorConfiguration,
  isConnectorGoogleAnalytics,
} from "core";
import { useGetGoogleAnalyticsAccounts } from "../../utils/nango";
import { useUpsertConnection } from "../../utils/useConnections";
import classes from "./AccountSelector.module.css";

function AccountsLoadingPlaceholder() {
  return (
    <Stack gap="md">
      <Tooltip label="Analytic account">
        <Stack gap="xs">
          <Skeleton w="10%" h={15} />
          <Skeleton w="15%" h={12} />
        </Stack>
      </Tooltip>
      <Skeleton w="20%" h={15} ml="xs" />
      <Stack gap="xs" ml="xs">
        <Stack gap="xs" variant="subtle">
          <Skeleton w="14%" h={15} />
          <Skeleton w="15%" h={12} />
        </Stack>
        <Stack gap="xs" variant="subtle">
          <Skeleton w="12%" h={15} />
          <Skeleton w="15%" h={12} />
        </Stack>
      </Stack>
    </Stack>
  );
}

export function AccountSelector({
  configuration,
}: {
  configuration: ConnectorConfiguration;
}) {
  const { accounts, isGettingAccounts } = useGetGoogleAnalyticsAccounts();
  const { upsertConnection, isUpsertingConnection } = useUpsertConnection();
  const isAccountPropertyAlreadySelected =
    isConnectorGoogleAnalytics(configuration) && configuration.connectedAccount;

  if (isAccountPropertyAlreadySelected) return null;

  function isAccountPropertySelected(propertyName: string) {
    return (
      isConnectorGoogleAnalytics(configuration) &&
      configuration.connectedAccount?.propertyName === propertyName
    );
  }

  function onSelectAccountProperty(
    connectedAccount: GoogleAnalyticsConnectorConfiguration["connectedAccount"],
  ) {
    upsertConnection(
      {
        connector: Connector.GoogleAnalytics,
        nangoConnectionId: configuration.nangoConnectionId,
        nangoIntegrationId: configuration.nangoIntegrationId,
        connectedAccount,
      },
      {
        onSuccess() {
          notifications.show({
            color: "green",
            icon: <IconCheck size={18} />,
            message:
              "Your analytics account is connected! The data will be available shortly as we process the latest insights.",
          });
        },
        onError() {
          notifications.show({
            color: "red",
            icon: <IconBug size={18} />,
            message: `There was an error connecting to the analytics account, please contact our support team.`,
          });
        },
      },
    );
  }

  return (
    <Stack>
      <Title order={5}>Analytics Accounts</Title>
      <Text c="dimmed" size="sm">
        Select the account property (website or app) for which you would like to
        connect to.
      </Text>
      <Divider />
      {isGettingAccounts ? (
        <AccountsLoadingPlaceholder />
      ) : (
        <List listStyleType="none">
          <ScrollAreaAutosize scrollbars="y" mah={300}>
            {accounts.length === 0 ? (
              <Stack align="center" p="xl" ta="center">
                <ThemeIcon size={50} variant="transparent" color="dark">
                  <IconBrandGoogleAnalytics size={50} stroke={0.5} />
                </ThemeIcon>
                <Group>
                  <Text c="dimmed" size="sm">
                    No analytics accounts were found. Make sure you have
                    configured some accounts in your Analytics platform.
                  </Text>
                </Group>
              </Stack>
            ) : (
              accounts.map((acc, idx) => {
                const isLast = idx === accounts.length - 1;
                return (
                  <Stack key={`${acc.id}${idx}`} gap="xs">
                    <Tooltip label="Analytic account">
                      <Stack gap={0}>
                        <Text size="sm" fw="bold" c="dimmed">
                          {acc.name}
                        </Text>
                        <Text size="xs" c="dimmed">
                          {acc.id}
                        </Text>
                      </Stack>
                    </Tooltip>
                    <Text size="xs" c="dimmed" ml="xs">
                      Apps & Properties
                    </Text>
                    <Stack gap={0}>
                      {acc.properties.map((prop) => (
                        <List.Item
                          key={prop.name}
                          className={classes.accountItem}
                          mod={{ disabled: isUpsertingConnection }}
                          onClick={() => {
                            onSelectAccountProperty({
                              id: acc.id,
                              name: acc.name,
                              propertyName: prop.name,
                              propertyDisplayName: prop.displayName,
                            });
                          }}
                        >
                          <Group>
                            <Stack>
                              <Stack gap={0} variant="subtle">
                                <Text size="sm" fw="bold">
                                  {prop.displayName}
                                </Text>
                                <Text size="xs" c="dimmed">
                                  {prop.name.split("/")[1]}
                                </Text>
                              </Stack>
                            </Stack>
                            {isAccountPropertySelected(prop.name) && (
                              <ThemeIcon variant="transparent" size="sm">
                                <IconCheck />
                              </ThemeIcon>
                            )}
                          </Group>
                        </List.Item>
                      ))}
                    </Stack>
                    {!isLast && <Divider mb="sm" />}
                  </Stack>
                );
              })
            )}
          </ScrollAreaAutosize>
        </List>
      )}
    </Stack>
  );
}
