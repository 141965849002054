import { Connector, FactContent, Period, SalesBlockPathKeys } from "core";
import { trpc } from "./trpc";

export function useGetConnection(input: { connector: Connector }) {
  const utils = trpc.useUtils();

  const { data, isLoading, ...query } = trpc.connection.get.useQuery(input, {
    onSettled() {
      utils.facts.getFactsStartingWith.invalidate();
      utils.convos.getConvos.invalidate();
    },
  });

  return {
    connection: data,
    isGettingConnection: isLoading,
    ...query,
  };
}

export function useUpsertConnection() {
  const utils = trpc.useUtils();

  const { mutate, isLoading, ...mutation } = trpc.connection.upsert.useMutation(
    {
      onSettled() {
        utils.connection.get.invalidate();
        utils.connection.getGAAccounts.invalidate();
        utils.facts.getFactsStartingWith.invalidate();
      },
    },
  );

  return {
    upsertConnection: mutate,
    isUpsertingConnection: isLoading,
    ...mutation,
  };
}

export function useRevokeConnection() {
  const utils = trpc.useUtils();

  const { mutate, isLoading } = trpc.connection.revokeAccess.useMutation({
    onSettled() {
      utils.connection.get.invalidate();
      utils.facts.getFactsStartingWith.invalidate();
      utils.convos.getConvos.invalidate();
    },
  });

  return {
    revokeConnection: mutate,
    isRevokingConnection: isLoading,
  };
}

export function extractValue(content: FactContent) {
  if (content.format.type === "amount") {
    return content.format;
  }
  return undefined;
}

export const periodLabels = {
  [Period.Today]: "Today",
  [Period.Last7Days]: "Last 7 days",
  [Period.Last30Days]: "Last 30 days",
  [Period.Last3Months]: "Last 3 months",
} as const;

export const paymentStatusLabels = {
  [SalesBlockPathKeys.PaymentsSucceeded]: "Succeeded",
  [SalesBlockPathKeys.PaymentsUncaptured]: "Uncaptured",
  [SalesBlockPathKeys.PaymentsRefunded]: "Refunded",
  [SalesBlockPathKeys.PaymentsFailed]: "Failed",
} as const;

export type PeriodKey = keyof typeof periodLabels;

export function getSalesBlockPath(key: string, period: Period) {
  return `${SalesBlockPathKeys.Sales}/${key}/${period}`;
}
