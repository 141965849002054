import { Card, Group, Skeleton, Stack, Text } from "@mantine/core";
import clsx from "clsx";
import {
  Connector,
  Period,
  SalesBlockPathKeys,
  WidgetId,
  type Fact,
} from "core";
import { useCallback } from "react";
import { useIsGranted } from "../../../auth/useIsGranted";
import { TrendBadge } from "../../../components/badges/TrendBadge";
import { usePlatformConnector } from "../../../components/PlatformConnector/usePlatformConnector";
import { RestrictedFeature } from "../../../components/RestrictedFeature";
import { calculatePercentageChange, formatAmount } from "../../../utils/amount";
import { extractValue, getSalesBlockPath } from "../../../utils/useConnections";
import { useFactsStartsWith } from "../../../utils/useFacts";
import { BaseWidget, QuickLinkButton } from "./BaseWidget";
import baseWidgetClasses from "./BaseWidget.module.css";
import { widgetProps } from "./config";
import classes from "./SalesWidget.module.css";

function LoadingPlaceholder() {
  return (
    <Stack gap={0}>
      <Card bg="base.3" className={classes.topCard}>
        <Group justify="space-between" align="center" gap="xs" pb="xs">
          <Skeleton w="15%" h={20} />
          <Skeleton w="20%" h={30} />
        </Group>
      </Card>
      <Card bg="base.4" className={classes.card}>
        <Group justify="space-between" align="flex-end" h={170} gap="xs">
          <Group justify="space-between" align="center" w="100%" gap="xs">
            <Stack gap="xs">
              <Skeleton w="8vw" h={20} />
              <Skeleton w="3vw" h={20} />
            </Stack>
            <Skeleton w="28%" h={30} />
          </Group>
        </Group>
      </Card>
    </Stack>
  );
}

function LatestSales({ facts }: { facts: Fact[] }) {
  const getFactValue = useCallback(
    (blockPath: string) => {
      const fact = facts.find((f) => f.blockPath === blockPath);
      if (!fact) return undefined;

      return extractValue(fact.content);
    },
    [facts],
  );

  const lastMonthAmount = getFactValue(
    getSalesBlockPath("gross", Period.LastMonth),
  );
  const currentMonthAmount = getFactValue(
    getSalesBlockPath("gross", Period.MonthToDate),
  );
  const grossChange = calculatePercentageChange(
    lastMonthAmount?.value || 0,
    currentMonthAmount?.value || 0,
  );

  return (
    <Stack gap={0}>
      <Card className={classes.topCard}>
        <Group justify="space-between" align="center" gap="xs">
          <Text size="md" className={classes.text}>
            Last month
          </Text>
          <Text className={classes.amount}>
            {formatAmount(
              (lastMonthAmount?.value ?? 0) / 100,
              lastMonthAmount?.currency,
            )}
          </Text>
        </Group>
      </Card>
      <Card className={classes.card}>
        <Group justify="space-between" align="flex-end" h={170} gap="xs">
          <Group justify="space-between" align="center" w="100%" gap="xs">
            <Stack gap={0}>
              <Text size="md" className={classes.text}>
                This month
              </Text>
              {Math.abs(grossChange) !== Infinity && (
                <TrendBadge value={grossChange} iconSize="sm" size="xl" />
              )}
            </Stack>
            <Text className={classes.amount}>
              {formatAmount(
                (currentMonthAmount?.value ?? 0) / 100,
                currentMonthAmount?.currency,
              )}
            </Text>
          </Group>
        </Group>
      </Card>
    </Stack>
  );
}

export function SalesWidget() {
  const { facts, isLoading: isLoadingFacts } = useFactsStartsWith({
    startsWith: SalesBlockPathKeys.Sales,
  });
  const { onOpenConnect, isConnecting, isSyncing } = usePlatformConnector({
    connector: Connector.Stripe,
  });
  const { hasPermission } = useIsGranted({ permission: "connection.read" });

  const hasSalesData = facts.length > 0;
  const isLoading = isLoadingFacts || isSyncing;

  if (!hasPermission) return null;

  return (
    <BaseWidget
      title={widgetProps[WidgetId.Sales].label}
      redirectTo="sales"
      cardClassName={clsx(!hasSalesData && baseWidgetClasses.fixedWidgetCard)}
    >
      {isLoading ? (
        <LoadingPlaceholder />
      ) : (
        <RestrictedFeature minimize>
          {({ restrictedClassName }) => (
            <>
              {hasSalesData ? (
                <LatestSales facts={facts} />
              ) : (
                <>
                  <Text size="sm" c="dimmed">
                    Connect your Stripe account to NOAN and watch your business
                    grow with smart data and asset-driven intelligence.
                  </Text>
                  <QuickLinkButton
                    label="Connect your Stripe account"
                    className={restrictedClassName}
                    isLoading={isConnecting}
                    onClick={onOpenConnect}
                  />
                </>
              )}
            </>
          )}
        </RestrictedFeature>
      )}
    </BaseWidget>
  );
}
