export enum ProductPriceKey {
  BasicMonth = "basicmonth",
  BasicYear = "basicyear",
  BasicMonthV1 = "basicmonthv1",
  BasicMonthV2 = "basicmonthv2",
  ScaleMonth = "scalemonth",
  ScaleYear = "scaleyear",
}

export const products = [
  {
    name: "Basic",
    items: [
      /**
       * /!\ Keep this BasicMonthV2 at first so that it gets selected
       * when a new customer subscribe and we want to link its items (seats/orgs) in linkSubscriptionItems()
       */
      {
        id: ProductPriceKey.BasicMonthV2,
        priceId: "price_1NuBd1B8g7YKzplObqEsyU29",
        frequency: "month",
        paymentLink: "https://buy.stripe.com/9AQ17v2xv7056S4bIX",
        extraSeatsPriceId: "price_1P5mABB8g7YKzplOUb94ATkl",
        defaultSeats: 1,
        defaultOrganizations: 5,
        extraOrganizationsPriceId: "price_1QeHP2B8g7YKzplOW4mZo8LQ",
      },
      /**
       * @deprecated - Keep for compatibility with existing users.
       */
      {
        id: ProductPriceKey.BasicMonthV1,
        priceId: "price_1NuBd1B8g7YKzplObqEsyU29",
        frequency: "month",
        paymentLink: "https://buy.stripe.com/9AQ17v2xv7056S4bIX",
        extraSeatsPriceId: "price_1P5mABB8g7YKzplOUb94ATkl",
        defaultSeats: 1,
        defaultOrganizations: 5,
        extraOrganizationsPriceId: "price_1QXhQTB8g7YKzplOLQ9Olu40",
      },
      /**
       * @deprecated - Keep for compatibility with existing users.
       */
      {
        id: ProductPriceKey.BasicMonth,
        priceId: "price_1NuBd1B8g7YKzplObqEsyU29",
        frequency: "month",
        paymentLink: "https://buy.stripe.com/9AQ17v2xv7056S4bIX",
        extraSeatsPriceId: "price_1P5mABB8g7YKzplOUb94ATkl",
        defaultSeats: 1,
        extraOrganizationsPriceId: "price_1PG6SBB8g7YKzplOGzRRQ2q2",
      },
      /**
       * @deprecated - Keep for compatibility with existing users.
       */
      {
        id: ProductPriceKey.BasicYear,
        priceId: "price_1NuBd1B8g7YKzplOyMlzhiot",
        frequency: "year",
        paymentLink: "https://buy.stripe.com/4gw2bzgolbgla4gaES",
        extraSeatsPriceId: "price_1P5mABB8g7YKzplO8eCyBb4S",
        defaultSeats: 1,
        extraOrganizationsPriceId: "price_1PG6SBB8g7YKzplO2RDQIeQs",
      },
    ],
  },
  {
    /**
     * @deprecated - Keep for compatibility with existing users.
     */
    name: "Scale",
    items: [
      {
        id: ProductPriceKey.ScaleMonth,
        priceId: "price_1OuZJzB8g7YKzplOcf6qwQ3L",
        frequency: "month",
        paymentLink: "https://buy.stripe.com/fZeg2p9ZXdot90cbIY",
        extraSeatsPriceId: "price_1P6dlhB8g7YKzplOZD62JlBY",
        defaultSeats: 3,
        extraOrganizationsPriceId: "price_1PG6QTB8g7YKzplOYnBcsele",
      },
      {
        id: ProductPriceKey.ScaleYear,
        priceId: "price_1OuZJzB8g7YKzplO1fTdcvUM",
        frequency: "year",
        paymentLink: "https://buy.stripe.com/3csaI5b41esxekw14l",
        extraSeatsPriceId: "price_1P6dlhB8g7YKzplOUCJV6ysb",
        defaultSeats: 3,
        extraOrganizationsPriceId: "price_1PG6QTB8g7YKzplOPu4bjP2R",
      },
    ],
  },
];

// Price IDs of all NOAN products
export const productItemsPriceIds = products.flatMap((p) =>
  p.items.map((i) => i.priceId),
);

export const extraSeatsPriceIds = products
  .flatMap((p) => p.items)
  .map((i) => i.extraSeatsPriceId);

export const extraOrganizationsPriceIds = products
  .flatMap((p) => p.items)
  .map((i) => i.extraOrganizationsPriceId);
