import classes from "./StackIcons.module.css";

export function EventsIcon() {
  return (
    <svg
      width="205"
      height="229"
      viewBox="0 0 205 229"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.stackIcon}
    >
      <path
        d="M136.469 94.6533L136.468 114.335L85.3335 143.857L68.2891 134.016L68.2891 114.335L85.3331 124.175L136.469 94.6533Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77 65.3757C77 60.4052 72.9706 56.3757 68 56.3757C63.0294 56.3757 59 60.4052 59 65.3757H35V163.376H170V65.3757H146C146 60.4052 141.971 56.3757 137 56.3757C132.029 56.3757 128 60.4052 128 65.3757H111.5C111.5 60.4052 107.471 56.3757 102.5 56.3757C97.5294 56.3757 93.5 60.4052 93.5 65.3757H77ZM53 83.3757H59C59 88.3463 63.0294 92.3757 68 92.3757C72.9706 92.3757 77 88.3463 77 83.3757H93.5C93.5 88.3463 97.5294 92.3757 102.5 92.3757C107.471 92.3757 111.5 88.3463 111.5 83.3757H128C128 88.3463 132.029 92.3757 137 92.3757C141.971 92.3757 146 88.3463 146 83.3757H152V145.376H53V83.3757Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M204.537 69.1883L204.536 153.738C204.536 165.917 198.038 177.171 187.491 183.261L114.268 225.536C106.842 229.823 97.6937 229.823 90.2681 225.536L12 180.348C4.57443 176.061 6.84045e-05 168.138 4.77098e-05 159.563L0 75.0123C2.01768e-05 62.8334 6.49753 51.5796 17.0448 45.4902L90.269 3.21534C97.6945 -1.07177 106.843 -1.07178 114.269 3.21531L192.537 48.4036C199.963 52.6908 204.537 60.6139 204.537 69.1883ZM21.0448 62.8622L98.2688 18.278C100.744 16.849 103.794 16.849 106.269 18.2781L183.492 62.8631C185.967 64.2921 187.492 66.9332 187.492 69.7913L187.491 158.96C187.491 161.818 185.966 164.459 183.491 165.888L106.268 210.474C103.793 211.903 100.743 211.903 98.2683 210.474L21.0447 165.888C18.5695 164.459 17.0448 161.818 17.0448 158.96L17.0447 69.7905C17.0447 66.9323 18.5695 64.2913 21.0448 62.8622Z"
        fill="currentColor"
      />
    </svg>
  );
}
