import {
  ActionIcon,
  Avatar,
  Button,
  Checkbox,
  Collapse,
  Group,
  List,
  MantineColor,
  Skeleton,
  Stack,
  Text,
  Textarea,
  TextInput,
  ThemeIcon,
  Title,
  Tooltip,
  Transition,
} from "@mantine/core";
import { isNotEmpty, useForm } from "@mantine/form";
import { useHover, useToggle } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import {
  IconCheckbox,
  IconChevronDown,
  IconChevronUp,
  IconCircleCheckFilled,
  IconDeviceFloppy,
  IconTrash,
} from "@tabler/icons-react";
import clsx from "clsx";
import { WidgetId, type Task } from "core";
import { useMemo, useState } from "react";
import { useAuthContext } from "../../../auth/useAuthContext";
import { useTheme } from "../../../components/ThemeProvider/useTheme";
import { LockActionIcon } from "../../../components/buttons/LockActionIcon";
import { arrayWithNElements } from "../../../utils/arrays";
import { useClientMode } from "../../../utils/useClientMode";
import {
  useCreateTask,
  useDeleteTask,
  useTasks,
  useUpdateTask,
} from "../../../utils/useTasks";
import { getInitials } from "../useDashboard";
import { BaseWidget } from "./BaseWidget";
import classes from "./TasksWidget.module.css";
import { widgetProps } from "./config";

function LoadingPlaceholder() {
  return (
    <BaseWidget title={widgetProps[WidgetId.Tasks].label}>
      <Skeleton h={28} w="100%" radius="lg" />
      <List listStyleType="none" spacing="xs">
        {arrayWithNElements(3).map((idx) => (
          <List.Item key={idx} w="100%">
            <Group wrap="nowrap" align="flex-start">
              <Skeleton h={20} w={20} />
              <Stack w="200px">
                <Skeleton h={10} w="35%" />
                <Skeleton h={10} w="60%" />
              </Stack>
            </Group>
          </List.Item>
        ))}
      </List>
    </BaseWidget>
  );
}

function DeleteAction({
  taskId,
  onUndo,
}: {
  taskId: string;
  onUndo: () => void;
}) {
  const { deleteTask } = useDeleteTask();

  return (
    <Tooltip withArrow label="Delete task">
      <ActionIcon
        size="sm"
        variant="transparent"
        color="dark"
        onClick={() =>
          deleteTask(
            { id: taskId },
            {
              onSuccess() {
                notifications.show({
                  id: taskId,
                  icon: <IconTrash size={18} />,
                  message: (
                    <Group justify="space-between">
                      Task deleted.{" "}
                      <Button variant="transparent" onClick={onUndo}>
                        Undo
                      </Button>
                    </Group>
                  ),
                  autoClose: 5000,
                });
              },
            },
          )
        }
      >
        <IconTrash size={18} />
      </ActionIcon>
    </Tooltip>
  );
}

function CreatorAvatar({
  name,
  color,
}: {
  name: string;
  color?: MantineColor;
}) {
  const creatorInitials = getInitials(name.toUpperCase());

  return (
    <Tooltip withArrow label={`Created by ${name}`}>
      <Avatar variant="light" color={color} size="sm">
        {creatorInitials}
      </Avatar>
    </Tooltip>
  );
}

function TaskItem({
  task,
  isEditingCurrentTask,
  isUpdatingTask,
  isOwner,
  avatarColor,
  updatedTask,
  onFocus,
  onUndo,
  onSetTaskEdit,
  onUpdateTask,
  onChangeInput,
}: {
  task: Task;
  isUpdatingTask: boolean;
  isEditingCurrentTask: boolean;
  isOwner: boolean;
  avatarColor?: MantineColor;
  onFocus: () => void;
  onUndo: () => void;
  onSetTaskEdit: () => void;
} & Pick<TasksListProps, "updatedTask" | "onUpdateTask" | "onChangeInput">) {
  const { ref: refItem, hovered: isItemHovered } = useHover<HTMLLIElement>();
  const [taskVanish, setTaskVanish] = useState(false);

  return (
    <Transition
      mounted={!taskVanish}
      transition="fade"
      duration={400}
      timingFunction="ease"
    >
      {(styles) => (
        <List.Item
          classNames={{
            itemLabel: classes.fullwidth,
          }}
          style={styles}
          ref={refItem}
        >
          <Group wrap="nowrap" align="flex-start" onFocus={onFocus}>
            <Checkbox
              classNames={{
                root: classes.taskCheckboxRoot,
                labelWrapper: classes.fullwidth,
              }}
              onChange={(e) => {
                onUpdateTask(
                  task.id,
                  "completed",
                  e.currentTarget.checked,
                  true,
                );
                setTaskVanish(true);
              }}
              label=""
              defaultChecked={task.completed}
              readOnly={isUpdatingTask}
              onClick={(e) => e.stopPropagation()}
            />
            <Stack gap={0} flex={1} onFocus={onSetTaskEdit}>
              <TextInput
                title={task.title}
                classNames={{
                  input: clsx(classes.taskTitleInput, {
                    [classes.taskCompleted]:
                      task.completed && !isEditingCurrentTask,
                  }),
                }}
                placeholder="Title"
                required
                autoFocus={isEditingCurrentTask}
                size="sm"
                defaultValue={task.title}
                onChange={(e) => {
                  onChangeInput(task.id, "title", e.currentTarget.value);
                }}
                onBlur={() => {
                  if (!updatedTask?.title) return;
                  onUpdateTask(task.id, "title", updatedTask.title);
                }}
                readOnly={isUpdatingTask}
                onClick={(e) => e.stopPropagation()}
              />
              {(task.details || isEditingCurrentTask) && (
                <Textarea
                  classNames={{
                    input: clsx(classes.taskDescriptionInput),
                  }}
                  placeholder="Details"
                  defaultValue={task.details}
                  autosize
                  minRows={2}
                  maxRows={12}
                  onChange={(e) => {
                    onChangeInput(task.id, "details", e.currentTarget.value);
                  }}
                  onBlur={() =>
                    onUpdateTask(task.id, "details", updatedTask?.details ?? "")
                  }
                  readOnly={isUpdatingTask}
                  onClick={(e) => e.stopPropagation()}
                />
              )}
            </Stack>
            <Group gap={2} align="center" mt={4}>
              {isItemHovered && isOwner && (
                <DeleteAction taskId={task.id} onUndo={onUndo} />
              )}
              {isOwner ? (
                <LockActionIcon
                  isLocked={task.private}
                  onClick={() =>
                    onUpdateTask(task.id, "isPrivate", !task.private, true)
                  }
                />
              ) : (
                <CreatorAvatar name={task.creator.name} color={avatarColor} />
              )}
            </Group>
          </Group>
        </List.Item>
      )}
    </Transition>
  );
}

type TasksListProps = {
  tasks: Task[];
  isUpdatingTask: boolean;
  updatedTask: UpdatedTask;
  onFocus: () => void;
  onUpdateTask: (
    taskId: string,
    key: string,
    value: string | boolean | null,
    skipCheck?: boolean,
  ) => void;
  onChangeInput: (
    taskId: string,
    key: string,
    value: string | boolean | null,
  ) => void;
  onUndo: (taskId: string) => void;
};

function TasksList({
  tasks,
  isUpdatingTask,
  updatedTask,
  onFocus,
  onChangeInput,
  onUpdateTask,
  onUndo,
}: TasksListProps) {
  const [editTaskId, setEditTaskId] = useState<string | null>(null); // Tracks the task being edited inline

  const { identity } = useAuthContext();
  const { theme } = useTheme();

  return (
    <Stack gap={0}>
      <List
        listStyleType="none"
        spacing={0}
        classNames={{
          itemWrapper: classes.fullwidth,
        }}
      >
        {tasks.map((t) => {
          const isEditingCurrentTask = t.id === editTaskId;
          return (
            <TaskItem
              key={t.id}
              task={t}
              isUpdatingTask={isUpdatingTask}
              isEditingCurrentTask={isEditingCurrentTask}
              isOwner={t.creatorId === identity.id}
              avatarColor={theme.colors[theme.secondaryColor][4]}
              updatedTask={updatedTask}
              onUndo={() => onUndo(t.id)}
              onFocus={onFocus}
              onSetTaskEdit={() => setEditTaskId(t.id)}
              onUpdateTask={onUpdateTask}
              onChangeInput={onChangeInput}
            />
          );
        })}
      </List>
    </Stack>
  );
}

type UpdatedTask = {
  id: string;
  title?: string;
  details?: string;
  completed?: boolean;
} | null;

interface NewTaskFormFields {
  title?: string;
  details?: string;
  completed: boolean;
}

export function TasksWidget() {
  const [showNewTask, toggleShowNewTask] = useToggle();
  const [showCompletedTasks, toggleShowCompletedTasks] = useToggle();
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [updatedTask, setUpdatedTask] = useState<UpdatedTask>(null);
  const { tasks, isLoadingTasks, isFetching: isFetchingTasks } = useTasks();
  const { createTask, isCreatingTask } = useCreateTask();
  const { updateTask, isUpdatingTask } = useUpdateTask();
  const { isTasksEnabled } = useClientMode();

  const { getInputProps, isValid, reset, setFieldValue, values } =
    useForm<NewTaskFormFields>({
      clearInputErrorOnChange: true,
      validateInputOnChange: true,
      validateInputOnBlur: true,
      initialValues: {
        title: "",
        details: "",
        completed: false,
      },
      validate: {
        title: isNotEmpty(),
      },
    });

  const completedTasks = useMemo(() => {
    return tasks.filter((t) => t.completed);
  }, [tasks]);
  const incompleteTasks = useMemo(() => {
    return tasks.filter((t) => !t.completed);
  }, [tasks]);

  function onChangeInput(
    taskId: string,
    key: string,
    value: string | boolean | null,
  ) {
    setUpdatedTask({
      id: taskId,
      [key]: value,
    });
  }

  function onUpdateTask(
    taskId: string,
    key: string,
    value: string | boolean | null,
    skipCheck = false,
  ) {
    if (!skipCheck && !updatedTask) return;

    updateTask(
      {
        id: taskId,
        [key]: value,
      },
      {
        onSettled() {
          setUpdatedTask(null);
          showTaskSavedNotification();
        },
      },
    );
  }
  function onAddTask({
    isCompleted = false,
    keepNewTask = true,
  }: { isCompleted?: boolean; keepNewTask?: boolean } = {}) {
    if (!isValid() || !values.title) return;

    createTask(
      {
        title: values.title,
        details: values.details,
        completed: isCompleted,
      },
      {
        onSuccess() {
          resetAndToggleNewTask({ show: keepNewTask });
        },
      },
    );
  }

  function showTaskSavedNotification() {
    setIsUpdated(true);

    setTimeout(() => {
      setIsUpdated(false);
    }, 1200);
  }

  function resetAndToggleNewTask({ show }: { show: boolean }) {
    reset();
    toggleShowNewTask(show);
  }

  function addTaskIfValidInput() {
    if (values.title) {
      onAddTask({ keepNewTask: false });
    } else {
      resetAndToggleNewTask({ show: false });
    }
  }

  function renderTasksList(tasks: Task[]) {
    return (
      <TasksList
        tasks={tasks}
        isUpdatingTask={isUpdatingTask}
        updatedTask={updatedTask}
        onChangeInput={onChangeInput}
        onUpdateTask={onUpdateTask}
        onFocus={() => addTaskIfValidInput()}
        onUndo={(taskId: string) => {
          updateTask(
            {
              id: taskId,
              removedAt: null,
            },
            {
              onSettled() {
                notifications.hide(taskId);
              },
            },
          );
        }}
      />
    );
  }

  if (!isTasksEnabled) return null;
  if (isLoadingTasks) return <LoadingPlaceholder />;

  return (
    <BaseWidget
      title={widgetProps[WidgetId.Tasks].label}
      childrenContainerGap="xs"
      onClick={addTaskIfValidInput}
    >
      {(isUpdatingTask || isUpdated) && (
        <Group
          gap="xs"
          pos="absolute"
          top={26}
          right={60}
          className="animate-pulse"
        >
          <ThemeIcon
            variant="transparent"
            size="xs"
            color={isUpdated ? "teal.6" : "dark"}
          >
            <IconDeviceFloppy />
          </ThemeIcon>
          <Text size="xs" c="dimmed">
            {isUpdated ? "Saved!" : "Saving..."}
          </Text>
        </Group>
      )}
      <Button
        mx={-15}
        leftSection={<IconCheckbox />}
        variant="subtle"
        justify="flex-start"
        radius="lg"
        onClick={(e) => {
          e.stopPropagation();
          if (values.title) {
            onAddTask({ keepNewTask: true });
          } else {
            resetAndToggleNewTask({ show: true });
          }
        }}
      >
        Add a task
      </Button>
      <Transition
        mounted={showNewTask}
        transition="pop"
        duration={400}
        timingFunction="ease"
      >
        {(styles) => (
          <Group wrap="nowrap" align="flex-start" style={styles}>
            <Checkbox
              classNames={{
                root: classes.taskCheckboxRoot,
                labelWrapper: classes.taskCheckboxLabelWrapper,
              }}
              style={styles}
              label=""
              readOnly={isCreatingTask}
              {...getInputProps("completed")}
              checked={values.completed}
              onChange={(e) => {
                const isChecked = e.currentTarget.checked;
                setFieldValue("completed", isChecked);
                if (isChecked && !!values.title) {
                  onAddTask({ isCompleted: isChecked });
                }
              }}
              onClick={(e) => e.stopPropagation()}
            />
            <Stack gap={0}>
              <TextInput
                classNames={{
                  input: classes.taskTitleInput,
                }}
                autoFocus
                placeholder="Title"
                {...getInputProps("title")}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    onAddTask();
                  }
                }}
                readOnly={isCreatingTask}
                onClick={(e) => e.stopPropagation()}
              />
              <Textarea
                classNames={{
                  input: clsx(classes.taskDescriptionInput),
                }}
                size="sm"
                placeholder="Details"
                rows={2}
                {...getInputProps("details")}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    onAddTask();
                  }
                }}
                readOnly={isCreatingTask}
                onClick={(e) => e.stopPropagation()}
              />
            </Stack>
          </Group>
        )}
      </Transition>
      <Transition
        mounted={
          tasks.length === completedTasks.length &&
          !showNewTask &&
          !isCreatingTask &&
          !isFetchingTasks
        }
        transition="pop"
        duration={200}
        timingFunction="ease"
      >
        {(styles) => (
          <Stack align="center" gap="xs" style={styles}>
            <ThemeIcon size={40} variant="transparent">
              <IconCircleCheckFilled size={40} />
            </ThemeIcon>
            <Text size="lg" c="dimmed">
              All tasks complete!
            </Text>
          </Stack>
        )}
      </Transition>
      {incompleteTasks.length > 0 && renderTasksList(incompleteTasks)}
      {completedTasks.length > 0 && (
        <>
          <Group gap="xs" onClick={() => toggleShowCompletedTasks()}>
            <ActionIcon variant="transparent" radius="xl" color="dark">
              {showCompletedTasks ? <IconChevronUp /> : <IconChevronDown />}
            </ActionIcon>
            <Title order={5}>Completed ({completedTasks.length})</Title>
          </Group>
          <Collapse in={showCompletedTasks}>
            {renderTasksList(completedTasks)}
          </Collapse>
        </>
      )}
    </BaseWidget>
  );
}
