import { FeedScope } from "core";
import { trpc } from "./trpc";

export function useFeedEntries(input: { scope: FeedScope }) {
  const { data, isLoading, ...rest } = trpc.feedEntry.getAll.useQuery(input);

  return {
    feed: data ?? [],
    isLoadingFeed: isLoading,
    ...rest,
  };
}
