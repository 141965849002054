import {
  Divider,
  Group,
  Tooltip as MantineTooltip,
  SimpleGrid,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { Period } from "core";
import { TrendBadge } from "../../components/badges/TrendBadge";
import { calculatePercentageChange } from "../../utils/amount";
import { useGetGoogleAnalyticsReports } from "../../utils/nango";
import classes from "./LatestTrendsView.module.css";

function formatNumber(input?: number) {
  return input ? input.toString().padStart(2, "0") : "0";
}

function Tooltip({
  label,
  dark,
  children,
}: React.PropsWithChildren<{ label: string; dark?: boolean }>) {
  return (
    <MantineTooltip
      withArrow
      bg={dark ? "dark" : undefined}
      position="top-start"
      label={label}
      multiline
      maw={250}
    >
      {children}
    </MantineTooltip>
  );
}

function MetricValue({
  name,
  type = "default",
  previousValue = 0,
  value = 0,
  tooltip,
  tooltipDark = false,
  reverseColor = false,
}: {
  name: string;
  type?: "default" | "rate";
  previousValue?: number;
  value?: number;
  tooltip: string;
  tooltipDark?: boolean;
  reverseColor?: boolean;
}) {
  function formatValue() {
    switch (type) {
      case "rate":
        return `${formatNumber(Number((value * 100).toFixed(2)))}%`;
      default:
        return formatNumber(value);
    }
  }

  const percentChange = calculatePercentageChange(previousValue, value);
  const reversed =
    percentChange > 0 ? "red.6" : percentChange < 0 ? "green.9" : undefined;
  const color = reverseColor ? reversed : undefined;

  return (
    <Stack gap={0}>
      <Group gap="xs">
        <Tooltip dark={tooltipDark} label={tooltip}>
          <Title order={4} fw="bold">
            {formatValue()}
          </Title>
        </Tooltip>
        <TrendBadge color={color} value={percentChange} />
      </Group>
      <Text size="sm">{name}</Text>
    </Stack>
  );
}

export function LatestTrendsView({
  contentLoadingPlaceholder,
}: {
  contentLoadingPlaceholder: JSX.Element;
}) {
  const { reports, isGettingReports } = useGetGoogleAnalyticsReports();

  const currentMonthPerf = reports?.performanceOverviewReports?.filter(
    (report) => report.period === Period.MonthToDate,
  )[0];
  const lastMonthPerf = reports?.performanceOverviewReports?.filter(
    (report) => report.period === Period.LastMonth,
  )[0];

  return isGettingReports ? (
    contentLoadingPlaceholder
  ) : (
    <Stack>
      <Title order={5}>This month</Title>
      <Stack className={classes.card}>
        <Tooltip dark label="Understand user engagement trends over time.">
          <Title order={5}>Traffic overview</Title>
        </Tooltip>
        <SimpleGrid cols={2}>
          <MetricValue
            name="Page views"
            tooltipDark
            previousValue={lastMonthPerf?.pageviews}
            value={currentMonthPerf?.pageviews}
            tooltip="Total number of pages viewed, including repeated views."
          />
          <MetricValue
            name="Active users"
            tooltipDark
            previousValue={lastMonthPerf?.activeUsers}
            value={currentMonthPerf?.activeUsers}
            tooltip="Number of users who engaged with your site or app during the specified period."
          />
          <MetricValue
            name="Unique visitors"
            tooltipDark
            previousValue={lastMonthPerf?.totalUsers}
            value={currentMonthPerf?.totalUsers}
            tooltip="Total number of people who visited your site or app in the given timeframe."
          />
        </SimpleGrid>
      </Stack>
      <Divider />
      <Title order={5}>Engagement</Title>
      <SimpleGrid cols={2}>
        <MetricValue
          name="Bounce rate"
          type="rate"
          previousValue={lastMonthPerf?.bounceRate}
          value={currentMonthPerf?.bounceRate}
          tooltip="Percentage of sessions where users left without further interaction."
          reverseColor
        />
        <MetricValue
          name="Engagement rate"
          type="rate"
          previousValue={lastMonthPerf?.engagementRate}
          value={currentMonthPerf?.engagementRate}
          tooltip="Percentage of sessions lasting over 10 seconds, having a conversion, or multiple page views."
        />
        <MetricValue
          name="Conversions"
          previousValue={lastMonthPerf?.conversions}
          value={currentMonthPerf?.conversions}
          tooltip="Total number of completed goal actions on your site."
        />
      </SimpleGrid>
    </Stack>
  );
}
