import { z } from "zod";
import { IdentityRole } from "./Identity";

export enum FeatureId {
  BuildMode = "build-mode",
  CreateMode = "create-mode",
  DashboardAssistant = "dashboard-assistant",
  News = "news",
  Tasks = "tasks",
}

const FeatureSettingValueSchema = z.object({});

export const FeatureSettingFiltersSchema = z.object({
  role: z.nativeEnum(IdentityRole).optional(),
  featureId: z.nativeEnum(FeatureId).optional(),
});

export const FeatureSettingGetSchema = z.object({
  role: z.nativeEnum(IdentityRole),
  featureId: z.nativeEnum(FeatureId),
});

export const FeatureSettingUpsertSchema = z.object({
  role: z.nativeEnum(IdentityRole),
  featureId: z.nativeEnum(FeatureId),
  enabled: z.boolean().optional(),
  value: z.record(z.string(), z.unknown()).optional(),
});

export type FeatureSettingFilters = z.infer<typeof FeatureSettingFiltersSchema>;
export type FeatureSettingGet = z.infer<typeof FeatureSettingGetSchema>;
export type FeatureSettingUpsert = z.infer<typeof FeatureSettingUpsertSchema>;
export type FeatureSettingValue = z.infer<typeof FeatureSettingValueSchema>;
