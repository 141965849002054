/**
 * Mantine style imports:
 */
import "@fontsource-variable/inter";
import "@mantine/carousel/styles.css";
import "@mantine/charts/styles.css";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/dropzone/styles.css";
import "@mantine/notifications/styles.css";
import "@mantine/tiptap/styles.css";

import React from "react";
import ReactDOM from "react-dom/client";
import { AppContainer } from "./App.tsx";

/**
 * Global css:
 */
import "./index.css";
import { initSentry } from "./sentry.ts";

/**
 * Start capturing errors with Sentry early; we'll also wrap this into
 * our react-router within the main App component:
 */
initSentry();

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <AppContainer />
  </React.StrictMode>,
);
