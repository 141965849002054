import type { Fact, FactWithState } from "core";
import uniqBy from "lodash.uniqby";
import { useAuthContext } from "../auth/useAuthContext";
import { trpc } from "./trpc";

export function useFacts() {
  return trpc.facts.getAll.useSuspenseQuery(undefined);
}

export function useFact(input: { factId: string } | { blockPath: string }) {
  const query = trpc.facts.getFact.useQuery(input);

  return {
    ...query,
    fact: query.data,
  };
}

export function useFactsStartsWith(input: { startsWith: string }) {
  const query = trpc.facts.getFactsStartingWith.useQuery(input);

  return {
    ...query,
    facts: query.data ?? [],
  };
}

export function useCreateFactVersion() {
  const utils = trpc.useUtils();
  const mutation = trpc.facts.create.useMutation({
    onSuccess(f) {
      // We're likely interested in refetching the parent, as well as its heritage:
      if (f?.parentFactId) {
        utils.facts.getFact.invalidate({ factId: f.parentFactId });
        utils.facts.getFactHeritage.invalidate({ factId: f.parentFactId });
      }

      // Bust the top-level cache on the 'resolved' state, too:
      utils.facts.getAll.invalidate();
    },
  });

  return {
    ...mutation,
    createFactVersion: mutation.mutate,
  };
}

export function useFactHeritage(factId: string) {
  return trpc.facts.getFactHeritage.useSuspenseQuery({
    factId,
  });
}

export function useRevertToFactVersion() {
  const utils = trpc.useUtils();
  const mutation = trpc.facts.revertToVersion.useMutation({
    onSettled(f) {
      utils.facts.getAll.invalidate();

      if (f) {
        utils.facts.getFact.invalidate({ factId: f.id });
        utils.facts.getFactHeritage.invalidate({ factId: f.id });

        if (f.parentFactId) {
          utils.facts.getFact.invalidate({ factId: f.parentFactId });
          utils.facts.getFactHeritage.invalidate({ factId: f.parentFactId });
        }
      }
    },
  });

  return {
    ...mutation,
    revertToFactVersion: mutation.mutate,
  };
}

/**
 * Given a specific fact, and a list with facts representing the current
 * 'state of the world' (resolved facts), figure out if we have any broken
 * references.
 *
 * TODO: This duplicates FactWithState, and can probably be removed
 */
export function useFactReferences({
  fact,
  resolvedFacts,
}: {
  fact: Fact;
  resolvedFacts: Fact[];
}) {
  const {
    factDependencyIds,
    content: { references },
  } = fact;

  const resolvedFactIds = resolvedFacts.map((f) => f.id);
  const brokenReferenceIds = factDependencyIds.filter(
    (id) => !resolvedFactIds.includes(id),
  );

  /**
   * Generates a list of broken references by their block paths, which
   * allows us to display broken reference warnings in a human-friendly
   * way without having to fetch any more data from the API.
   */
  const brokenBlockPathReferences = references.filter((ref) => {
    if (ref.refType !== "blockPath") {
      return false;
    }

    return resolvedFacts.find(
      (f) => f.blockPath === ref.blockPath && !factDependencyIds.includes(f.id),
    );
  });

  return {
    brokenReferenceIds,

    // TODO: This is only necessary since the code around this part is a bit messy:
    brokenBlockPathReferences: uniqBy(
      brokenBlockPathReferences,
      (b) => b.blockPath,
    ),
  };
}

export function getFactContentText(fact: Fact) {
  return fact.content.plainText!;
}

export function useDeleteAllFacts() {
  const { mutate, isLoading, ...rest } = trpc.facts.deleteAll.useMutation();

  return {
    ...rest,
    deleteAllFacts: mutate,
    isDeletingAllFacts: isLoading,
  };
}

function useFactPermissions({ identityRole }: { identityRole: string }) {
  const { identity } = useAuthContext();

  return trpc.facts.getFactPermissions.useQuery(
    {
      identityRole,
    },
    {
      enabled: !!identity != null,
      meta: {
        persist: true,
      },
    },
  );
}

export function useAllowedBlocks({ role }: { role: string }) {
  const { data, refetch, isLoading } = useFactPermissions({
    identityRole: role,
  });

  const blocks =
    data && data.length > 0
      ? (data.flatMap((d) => d.blockPaths) as string[])
      : undefined;

  return { blocks, refetch, isLoadingPermissions: isLoading };
}

/**
 * Thin wrappers around react-query/trpc mutations for FactPermissions.
 */
export function useFactPermissionsMutation() {
  const utils = trpc.useUtils();

  const {
    mutate: createFactPermissions,
    isLoading: isLoadingCreateFactPermissions,
  } = trpc.facts.createFactPermissions.useMutation({
    onSettled() {
      utils.facts.getFactPermissions.invalidate();
    },
  });

  const {
    mutate: updateFactPermissions,
    isLoading: isLoadingUpdateFactPermissions,
  } = trpc.facts.updateFactPermissions.useMutation({
    onSettled() {
      utils.facts.getFactPermissions.invalidate();
    },
  });

  return {
    createFactPermissions,
    isLoadingCreateFactPermissions,
    updateFactPermissions,
    isLoadingUpdateFactPermissions,
  };
}

export function isFactWithState(
  fact: Fact | FactWithState,
): fact is FactWithState {
  return (
    typeof (fact as FactWithState).outdatedReferenceBlockPaths !== "undefined"
  );
}

export function isOutdatedVersion(fact?: Fact | FactWithState | null) {
  return (
    !!fact &&
    isFactWithState(fact) &&
    fact.outdatedReferenceBlockPaths.length > 0
  );
}
