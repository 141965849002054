import { Badge, MantineColor, MantineSize, ThemeIcon } from "@mantine/core";
import { IconTrendingDown2, IconTrendingUp2 } from "@tabler/icons-react";
import classes from "./TrendBadge.module.css";

type TrendType = "default" | "error" | "success";

function getStyleProps(value: number): {
  type: TrendType;
  icon: JSX.Element | null;
} {
  if (value > 0)
    return {
      type: "success",
      icon: <IconTrendingUp2 />,
    };

  if (value < 0) {
    return {
      type: "error",
      icon: <IconTrendingDown2 />,
    };
  }

  return {
    type: "default",
    icon: null,
  };
}

export function TrendBadge({
  color,
  value,
  iconSize = "xs",
  size = "lg",
}: {
  value: number;
  iconSize?: MantineSize;
  size?: MantineSize;
  color?: MantineColor;
}) {
  const props = getStyleProps(value);

  if (Math.abs(value) === Infinity) {
    return null;
  }

  return (
    <Badge
      variant="transparent"
      size={size}
      className={classes.badge}
      c={color}
      mod={{
        [props.type]: !color,
      }}
      leftSection={
        props.icon ? (
          <ThemeIcon size={iconSize} variant="transparent" c={color}>
            {props.icon}
          </ThemeIcon>
        ) : undefined
      }
    >
      {value.toFixed(2)}%
    </Badge>
  );
}
