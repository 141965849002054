import { trpc } from "./trpc";

export function useTasks() {
  const { data, isLoading, ...rest } = trpc.task.getAll.useQuery();

  return {
    tasks:
      data?.map((t) => ({
        ...t,
        details: t.details ?? undefined,
      })) ?? [],
    isLoadingTasks: isLoading,
    ...rest,
  };
}

export function useTask({ taskId }: { taskId: string }) {
  const { data, isLoading, ...rest } = trpc.task.getTask.useQuery({ taskId });

  return {
    task: data,
    isLoadingTask: isLoading,
    ...rest,
  };
}

export function useCreateTask() {
  const utils = trpc.useUtils();

  const { mutate, isLoading, ...rest } = trpc.task.create.useMutation({
    onSettled() {
      utils.task.getAll.invalidate();
      utils.organization.getStats.invalidate();
    },
  });

  return {
    createTask: mutate,
    isCreatingTask: isLoading,
    ...rest,
  };
}

export function useUpdateTask() {
  const utils = trpc.useUtils();

  const { data, mutate, isLoading, ...rest } = trpc.task.update.useMutation({
    onSettled() {
      utils.task.getAll.invalidate();
      utils.organization.getStats.invalidate();
    },
  });

  return {
    updateTask: mutate,
    isUpdatingTask: isLoading,
    ...rest,
  };
}

export function useDeleteTask() {
  const utils = trpc.useUtils();

  const { data, mutate, isLoading, ...rest } = trpc.task.delete.useMutation({
    onSettled() {
      utils.task.getAll.invalidate();
    },
  });

  return {
    deleteTask: mutate,
    isDeletingTask: isLoading,
    ...rest,
  };
}
