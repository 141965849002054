import { isGranted, type ErrorCode, type PermissionPath } from "core";

import type { IdentityRole } from "core";
import { useAuthContext } from "./useAuthContext";

export function useIsGranted({
  defaultRole,
  permission,
}: {
  defaultRole?: IdentityRole;
  permission: PermissionPath;
  redirect?: ErrorCode;
}) {
  const { identity } = useAuthContext();

  if (!defaultRole && !identity?.primaryRole) {
    return {
      hasPermission: false,
      identity,
    };
  }

  const identityRole = defaultRole ?? identity?.primaryRole;
  const { hasPermission } = isGranted({ identityRole, permission });

  return {
    hasPermission,
    identity,
  };
}
