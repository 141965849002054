import { z } from "zod";

export enum Connector {
  Stripe = "stripe",
  GoogleAnalytics = "google-analytics",
}

export enum GABlockPathKeys {
  GA = "ga",
}

export enum NangoModelName {
  GAReportPerformanceOverview = "GAReportPerformanceOverview",
  GAReportTrafficOverview = "GAReportTrafficOverview",
  GAReportTopContent = "GAReportTopContent",
  GAReportTrafficSource = "GAReportTrafficSource",
  GAReportEngagement = "GAReportEngagement",
}

export const NangoIntegrationIds = {
  [Connector.Stripe]: "com.noan.stripe-connector",
  [Connector.GoogleAnalytics]: "com.noan.ga-connector",
} as const;

export const nangoIntegrations = Object.values(NangoIntegrationIds);

const NangoConnectionSchema = z.object({
  nangoConnectionId: z.string(),
  nangoIntegrationId: z.string(),
});

const StripeConnectorConfigurationSchema = z
  .object({
    connector: z.literal(Connector.Stripe),
  })
  .merge(NangoConnectionSchema);

const GoogleAnalyticsConnectorConfigurationSchema = z
  .object({
    connector: z.literal(Connector.GoogleAnalytics),
    /**
     * The Google Analytics account the organization is connected to. Can be a website or an app.
     */
    connectedAccount: z
      .object({
        id: z.string(),
        name: z.string(),
        propertyName: z.string(),
        propertyDisplayName: z.string(),
      })
      .optional(),
  })
  .merge(NangoConnectionSchema);

export const ConnectorConfigurationSchema = z.discriminatedUnion("connector", [
  StripeConnectorConfigurationSchema,
  GoogleAnalyticsConnectorConfigurationSchema,
]);

export type GoogleAnalyticsConnectorConfiguration = z.infer<
  typeof GoogleAnalyticsConnectorConfigurationSchema
>;

export type ConnectorConfiguration = z.infer<
  typeof ConnectorConfigurationSchema
>;

export function isConnectorGoogleAnalytics(
  configuration?: ConnectorConfiguration,
) {
  return configuration?.connector === Connector.GoogleAnalytics;
}
